import { useQuery } from "react-query";
import { MigrationSetWithImports, useSmokeballApp } from "../shared";
import log from "../shared/utils/log";
import { requestHelper, UnauthorizedError } from "../shared/utils";
import { useState } from "react";

const fetchMigration = async ({
  accountId,
  migrationSetId,
  token,
}: {
  accountId: string;
  migrationSetId: number;
  token?: () => Promise<string>;
}): Promise<MigrationSetWithImports> => {
  const endpoint = `/${migrationSetId}`;
  const data = await requestHelper(
    accountId,
    endpoint,
    "Failed to retrieve import.",
    "GET",
    token,
  );
  log("Fetched migration set: ", data);

  return data.migrationSet;
};

export const useMigration = ({
  accountId,
  migrationSetId,
  token,
}: {
  accountId: string;
  migrationSetId: number;
  token?: () => Promise<string>;
}) => {
  const { context } = useSmokeballApp();
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["migration", accountId, migrationSetId],
    queryFn: async () => {
      try {
        const result = await fetchMigration({
          accountId,
          migrationSetId,
          token,
        });
        setIsUnauthorized(false); // Reset on success
        return result;
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          // Stop retrying on UnauthorizedError
          setIsUnauthorized(true);
        }
        throw error;
      }
    },
    refetchInterval: 5000,
    // Do not run the query until accountId is valid
    enabled: !!accountId,
    retry: (failureCount, error) => {
      // Stop retrying on UnauthorizedError
      return !(error instanceof UnauthorizedError);
    },
  });

  const currentUser = context?.userId;
  const blockingUserName = data ? getBlockingUser(data, currentUser) : null;

  // Check if any import is in progress by another user
  const disableAccessToSecondaryUser = blockingUserName !== null;
  const disableAccessAsPrimaryUserCancelled = data?.status === "Cancelled";
  const disableAccessAsPrimaryUserSubmitted =
    data?.status === "Submitted" ||
    data?.status === "Loading" ||
    data?.status === "Complete";

  return {
    data,
    refetch,
    isLoading,
    isUnauthorized,
    blockingUserName,
    disableAccessToSecondaryUser,
    disableAccessAsPrimaryUserCancelled,
    disableAccessAsPrimaryUserSubmitted,
  };
};

const getBlockingUser = (
  data: MigrationSetWithImports | undefined,
  currentUser: string | undefined,
) => {
  const importBlocking = data?.imports.find(
    (importData) =>
      (importData.uploadInProgress as unknown as number) === 1 &&
      importData.lastUpdatedBy !== currentUser,
  );
  return importBlocking ? importBlocking.lastUpdatedByName : null;
};
