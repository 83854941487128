import { SDKError } from "./sdk";

export const buildLogProps = (error: unknown) => {
  let errorProps;
  let stack;
  let logMessage;
  let statusCode = 500;

  if (error instanceof SDKError) {
    errorProps = {
      name: error.name,
      message: error.message,
      action: error.action,
      input: error.input,
    };
    stack = error.stack;
    logMessage = "SDK error occurred";
  } else if (error instanceof Error) {
    errorProps = {
      message: error.message,
    };
    stack = error.stack;
    logMessage = "Error occurred";
  } else {
    errorProps = {
      message: `${error}`,
    };
    logMessage = "Unknown error occurred";
  }

  return { errorProps, stack, logMessage, statusCode };
};

export type LogProps = ReturnType<typeof buildLogProps>;

export const logError = (error: unknown, parent?: any) => {
  const { errorProps, stack, logMessage, statusCode } =
    buildLogProps(error);

  console.error(
    JSON.stringify({
      ...errorProps,
      stack,
      parent: typeof parent === "string" ? parent : JSON.stringify(parent),
      reason: errorProps.message,
      message: logMessage,
    })
  );

  return {
    errorProps,
    stack,
    logMessage,
    statusCode,
  };
};
