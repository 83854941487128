import { useMutation } from "react-query";
import { MigrationSetImportWithUploadLink } from "../shared";
import { useCallback } from "react";
import log from "../shared/utils/log";
import { requestHelper } from "../shared/utils";

const cancelOneSchemaSession = async ({
  accountId,
  migrationSetId,
  migrationImportId,
  token,
}: {
  accountId: string;
  migrationSetId: number;
  migrationImportId: number;
  token?: () => Promise<string>;
}): Promise<MigrationSetImportWithUploadLink> => {
  const endpoint = `/${migrationSetId}/${migrationImportId}/cancel`;
  const data = await requestHelper(
    accountId,
    endpoint,
    "Failed to cancel the upload.",
    "POST",    
    token,
  );
  log("Updated data for migration set import: ", data);

  return data;
};

export const useCancelOneSchemaSession = () => {
  const { mutateAsync, isLoading } = useMutation(cancelOneSchemaSession);

  const handleCancelOnceSchemaSessionClick = useCallback(async ({
    accountId,
    migrationSetId,
    migrationImportId,
    token,
  }: {
    accountId: string;
    migrationSetId: number;
    migrationImportId: number;
    token?: () => Promise<string>;
  }) => {
    try {
      await mutateAsync({
        accountId,
        migrationSetId,
        migrationImportId,
        token
      });
    } catch (error) {
      console.error("Error cancelling file upload: ", error);
    }
  }, [mutateAsync]);

  return {
    handleCancelOnceSchemaSessionClick,
    isLoading,
  };
};
