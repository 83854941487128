import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { basicRoutes } from "./routes/basic";
import { proRoutes } from "./routes/pro";

const router = createBrowserRouter([basicRoutes, proRoutes]);

createRoot(document.getElementById("migration-app-root") as HTMLElement).render(
  <RouterProvider router={router} />
);
