import { useEffect, useState } from "react";
import { Staff, UseSDKApiOptions } from "../types";
import { SDKError, logError } from "../utils/errors";

export const useStaff = ({ client, isInit, observe }: UseSDKApiOptions) => {
  const [loading, setLoading] = useState(true);
  const [currentStaff, setCurrentStaff] = useState<Staff[]>();

  const shouldObserve = observe?.includes("staff");

  useEffect(() => {
    if (isInit && client && shouldObserve) {
      setLoading(true);
      client.staff
        .all()
        .then((staff) => {
          setLoading(false);
          setCurrentStaff(staff);
        })
        .catch((err) => {
          setLoading(false);
          logError(new SDKError(err, { action: "staff.all" }));
        });

      client.staff.observe((staff) => setCurrentStaff(staff));
    }
  }, [client, isInit, shouldObserve]);

  if (!isInit || !client) return undefined;

  return {
    all: async () => {
      try {
        return await client.staff.all();
      } catch (error) {
        throw new SDKError(error, { action: "staff.all" });
      }
    },
    current: currentStaff,
    loading,
  };
};
