import {
  Loader,
  Modal,
  StandardButton,
  CancelButton,
  ModalParagraphText,
} from "../../../components";
import { MigrationSetImport, COLOURS, useSmokeballApp } from "../../../shared";
import React from "react";
import { useClearMigrationDataTypeUpload } from "../../../hooks/clearMigrationDataTypeUpload";
import { useNavigate } from "react-router-dom";
import { useMigration } from "../../../hooks";
import { CircleAlert } from "lucide-react";
import log from "../../../shared/utils/log";

interface ClearMigrationDataTypeUploadModalProps {
  migrationSetImport: MigrationSetImport | undefined;
  refetchMigrationSet: () => Promise<any>;
}

export const ClearMigrationDataTypeUploadModal: React.FC<
  ClearMigrationDataTypeUploadModalProps & { closeModal: () => void }
> = ({ closeModal, migrationSetImport, refetchMigrationSet }) => {
  const { auth, context } = useSmokeballApp();
  let migrationSet = useMigration({
    accountId: context?.firmId || "",
    migrationSetId: migrationSetImport?.migrationSetId || 0,
    token: auth?.token,
  });
  let migrationSetData = migrationSet.data;
  // Find the matterType import within the migration set
  const matterTypeImport = migrationSetData?.imports.find(
    (importItem) => importItem.importType === "matterType"
  );

  const navigate = useNavigate();
  const { handleClearMigrationDataTypeClick, isLoading } =
    useClearMigrationDataTypeUpload();

  const handleGoBack = () => {
    log("Go back clicked");
    closeModal();
  };

  const handleClear = async () => {
    if (!migrationSetImport) {
      console.error("No import set import provided");
      return;
    }

    try {
      // If a matterType import exists and you are clearing the matters data type, clear matter types first
      if (migrationSetImport.importType === "matter") {
        if (matterTypeImport) {
          await handleClearMigrationDataTypeClick({
            migrationSetImport: matterTypeImport,
            token: auth?.token,
          });
        }
      }

      await handleClearMigrationDataTypeClick({
        migrationSetImport: migrationSetImport,
        token: auth?.token,
      });
      refetchMigrationSet();
      navigate(
        `/embedded/jumpstart/workflow/${migrationSetImport.migrationSetId}`
      );
    } catch (error) {
      console.error("Error clearing import type:", error);
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <Modal
        backgroundColor={COLOURS.cancelAndClearIconBackground}
        icon={
          <CircleAlert
            color={COLOURS.cancelAndClearIconColor}
            strokeWidth={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        buttons={[
          {
            Button: StandardButton,
            label: "No, go back",
            action: handleGoBack,
          },
          {
            Button: CancelButton,
            label: "Yes, clear",
            action: handleClear,
          },
        ]}
        headingText={"Are you sure you want to clear the uploaded data?"}
      >
        <ModalParagraphText>
          Data for this type will be removed and you will need to re-upload it
          if you would like this data included in the import.
        </ModalParagraphText>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};
