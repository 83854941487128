import { useMutation, useQueryClient } from "react-query";
import { MigrationSet, useSmokeballApp } from "../shared";
import log from "../shared/utils/log";
import { requestHelper, UnauthorizedError } from "../shared/utils";
import { useState } from "react";

const fetchNewMigration = async ({
  accountId,
  token,
  importTypes,
}: {
  accountId: string;
  token?: () => Promise<string>;
  importTypes: string[];
}): Promise<MigrationSet> => {
  // Join the import types into a comma-separated string
  const importTypesQuery = importTypes.join(",");

  // Construct the endpoint with query parameters
  const endpoint = `/init?importTypes=${encodeURIComponent(importTypesQuery)}`;
  log("endpoint: ", endpoint);

  const data = await requestHelper(
    accountId,
    endpoint,
    "Failed to initialise new import.",
    "POST",
    token,
  );
  log("New import data: ", data);

  return data;
};

export const useNewMigration = () => {
  const { auth, context } = useSmokeballApp();
  const { mutateAsync, isLoading } = useMutation(fetchNewMigration);
  const accountId = context?.firmId || "";
  const token = auth?.token;
  const queryClient = useQueryClient();

  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const handleNewMigrationClick = async (selectedImportTypes: string[]) => {
    try {
      const result = await mutateAsync({
        accountId,
        token,
        importTypes: selectedImportTypes,
      });
      setIsUnauthorized(false); // Reset on success
      queryClient.invalidateQueries(["migrations", accountId]);
      log("Selected import types: ", selectedImportTypes);
      return result.migrationSetId; // Return migrationSetId to the caller
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        setIsUnauthorized(true);
        return null; // Return null on UnauthorizedError
      }
      throw error;
    }
  };

  return {
    handleNewMigrationClick,
    isLoading,
    isUnauthorized,
  };
};
