import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  Modal,
  ModalParagraphText,
  StandardButton,
  CancelButton,
} from "../../../components";
import { COLOURS, useSmokeballApp } from "../../../shared";
import { useCancelMigration } from "../../../hooks/";
import { CircleAlert } from "lucide-react";
import log from "../../../shared/utils/log";

export const CancelMigrationModal: React.FC<{
  closeModal: () => void;
  region: string;
}> = ({ closeModal, region }) => {
  const { auth, context } = useSmokeballApp();
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleCancelMigrationClick, isLoading } = useCancelMigration({
    accountId: context?.firmId || "",
    token: auth?.token,
  });

  const handleGoBack = () => {
    log("Go back clicked");
    closeModal();
  };

  const handleCancel = async () => {
    if (!id) {
      console.error("Import ID is missing.");
      return;
    }
    try {
      await handleCancelMigrationClick(id);
      navigate("/embedded/jumpstart");
    } catch (error) {
      console.error("Error cancelling import:", error);
    } finally {
      closeModal();
    }
  };

  return (
    <>
    <Modal
      backgroundColor={COLOURS.cancelAndClearIconBackground}
      icon={
        <CircleAlert
          color={COLOURS.cancelAndClearIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      buttons={[
        {
          Button: StandardButton,
          label: "No, go back",
          action: handleGoBack,
        },
        {
          Button: CancelButton,
          label: "Yes, cancel",
          action: handleCancel,
        },
      ]}
      headingText={"Are you sure you want to cancel the current import?"}
    >
      <ModalParagraphText>
        All progress will be lost, and the process reset.
      </ModalParagraphText>
    </Modal>
    {isLoading && <Loader />}
    </>
  );
};
