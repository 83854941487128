import { get, common } from "@smokeballdev/smokeball-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "./smokeballSDK.auth";
import { useFirm } from "./smokeballSDK.firm";
import { useStaff } from "./smokeballSDK.staff";
import { useHost } from "./smokeballSDK.host";
import { UseSmokeballAppOptions } from "../types";

const client: common.Api | null = get();

export const hasSDKClient = () => !!client;

export const useSDKClient = (options?: UseSmokeballAppOptions) => {
  const [isInit, setIsInit] = useState(!!client?.context);
  const hostType = useMemo((): "windows" | "web" | "local" | undefined => {
    if (!client) return "local";

    const constructor = client.constructor as {
      inApp?: () => boolean;
      inMock?: () => boolean;
      inWeb?: () => boolean;
    };
    if (constructor.inApp?.()) return "windows";
    if (constructor.inWeb?.()) return "web";
    if (constructor.inMock?.()) return "local";

    return undefined;
  }, []);

  useEffect(() => {
    if (!isInit && client && client.init) {
      client
        .init({ clientId: process.env.REACT_APP_MIG_JUMPSTART_CLIENT_ID})
        .then(() => setIsInit(true))
        .catch((err) => {
          console.error("Failed to init smokeball SDK client", err);
        });
    }
  }, [isInit]);

  const useSDKApiOptions = {
    client,
    hostType,
    isInit,
    observe: options?.observe,
  };

  return {
    hostType,
    context: isInit && client ? client.context : undefined,
    auth: useAuth(useSDKApiOptions),
    firm: useFirm(useSDKApiOptions),
    staff: useStaff(useSDKApiOptions),
    host: useHost(useSDKApiOptions),
  };
};

export type SmokeballAppClient = ReturnType<typeof useSDKClient>;
