import {
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import styled from "@emotion/styled";
import { COLOURS } from "../shared";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding: 1.6rem 0rem;
  user-select: none;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  overflow: auto;
`;

const TableHeader = styled.th<{ widthPercentage: number }>`
  width: ${(props) => `${props.widthPercentage}%`};
  color: ${COLOURS.tableHeaderColor};
  font-size: ${(props) => props.theme.fontSize.small};
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.4rem 0.8rem 1.4rem 2rem;
  border-bottom: 1px solid ${COLOURS.tableBorder};
`;

export const TableCell = styled.td<{ widthPercentage: number }>`
  white-space: normal;
  width: ${(props) => `${props.widthPercentage}%`};
  color: ${COLOURS.tableCellTextColor};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 400;
  padding: 1rem 2rem 1rem 2rem;
`;

const TableRow = styled.tr<{ index: number }>`
  background-color: ${(props) =>
    props.index % 2 === 0
      ? COLOURS.tableRowBackgroundGrey
      : COLOURS.tableRowBackgroundWhite};
  border-bottom: 1px solid ${COLOURS.tableBorder};
  height: 7.2rem;
`;

export const Table = <T extends object>({
  data,
  columns,
}: ReactTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <TableWrapper>
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  let widthPercentage = 0;
                  if (header.column.columnDef.size !== undefined) {
                    widthPercentage = header.column.columnDef.size;
                  }
                  return (
                    <TableHeader
                      key={header.id}
                      colSpan={header.colSpan}
                      widthPercentage={widthPercentage}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHeader>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <TableRow index={index} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    widthPercentage={cell.column.columnDef.size || 0}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </TableContainer>
  );
};
