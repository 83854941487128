import React from "react";
import { COLOURS } from "../shared";

const UnbilledExpensesSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={COLOURS.cardIconColor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeDasharray="5 5"
      d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z"
    ></path>
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8"></path>
  </svg>
);

const UnbilledExpensesUKSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={COLOURS.cardIconColor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeDasharray="5 5"
      d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6"
    ></path>
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9"></path>
  </svg>
);

const InvoicesSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={COLOURS.cardIconColor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z"></path>
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8"></path>
  </svg>
);

const InvoicesUKSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={COLOURS.cardIconColor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6"></path>
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9"></path>
  </svg>
);

export {
  UnbilledExpensesSVG,
  UnbilledExpensesUKSVG,
  InvoicesSVG,
  InvoicesUKSVG,
};
