import { usePresignedURLForLogFile } from "../../../hooks";
import { MigrationSetWithImports, useSmokeballApp } from "../../../shared";

interface Props {
  migrationSet: MigrationSetWithImports;
}

export const MigrationSetError: React.FC<Props> = ({ migrationSet }: Props) => {
  const { auth } = useSmokeballApp();
  const { data } = usePresignedURLForLogFile({
    accountId: migrationSet.accountId,
    migrationSetId: migrationSet.migrationSetId,
    fileName:
      migrationSet.loadLogFileName || migrationSet.validationLogFileName,
    token: auth?.token,
  });

  const failedRecordsCount = migrationSet.imports.reduce(
    (sum, i) => sum + i.loadErrorCount,
    0
  );

  const renderContent = () => {
    if (
      !migrationSet.validationLogFileName &&
      !migrationSet.loadLogFileName &&
      migrationSet.status === "Complete"
    ) {
      return <>All records successfully imported</>;
    } else if (
      migrationSet.loadLogFileName &&
      migrationSet.status === "Complete"
    ) {
      return (
        <>
          {data?.presignedURL ? (
            <>
              {`${failedRecordsCount} records failed to import. `}
              <a
                href={data.presignedURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
              {" failures."}
            </>
          ) : (
            `${failedRecordsCount} records failed to import. Download failures`
          )}
        </>
      );
    } else if (
      migrationSet.validationLogFileName &&
      migrationSet.status === "Failed Validation"
    ) {
      return (
        <>
          {data?.presignedURL ? (
            <>
              {"Some validation failures were identified. "}
              <a
                href={data.presignedURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
              {
                " failures. Please re-open this import, fix the validation issues as noted in the downloadable file, then retry."
              }
            </>
          ) : (
            `Some validation failures were identified. Download failures. Please re-open this import, fix the validation issues as noted in the downloadable file, then retry.`
          )}
        </>
      );
      // NOTE: We should not get here but if we do there is a catch all error
    } else {
      return (
        <>
          Unexpected error, please re-open and try again. If the issue persists,
          please contact Support via the help (?) button.
        </>
      );
    }
  };

  return renderContent();
};
