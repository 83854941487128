import { ThemeProvider } from "@emotion/react";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { defaultTheme, GlobalStyles, Loader } from "../../components";
import { SmokeballProvider } from "../../shared";
import { AuthProvider, useAuthContext } from "./authContext";

const client = new QueryClient();

const AppContent: React.FC = () => {
  const { isAuthInitialized } = useAuthContext();

  if (!isAuthInitialized) {
    return <Loader />; // Display loader until auth is initialized
  }

  return <Outlet />;
};

export const BasicApp = () => (
  <ThemeProvider theme={defaultTheme}>
    <SmokeballProvider observe={["staff"]}>
      <QueryClientProvider client={client}>
        <GlobalStyles />
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </QueryClientProvider>
    </SmokeballProvider>
  </ThemeProvider>
);
