import { RouteObject } from "react-router-dom";

import { BasicApp } from "./app";
import { Dashboard } from "./dashboard/index";
import { Workflow } from "./workflow";
import ErrorScreen from "./dashboard/authErrorScreen";

export const basicRoutes: RouteObject = {
  path: "/embedded/jumpstart",
  element: <BasicApp />,
  // Adding props here to then use in dashboard and workflow
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: "workflow/:id",
      element: <Workflow />,
    },
    {
      path: "error",
      element: <ErrorScreen />,
    },
  ],
};
