import React, { useState } from "react";
import {
  Checkbox,
  Loader,
  Modal,
  ModalParagraphText,
  StandardButton,
  SubmitButton,
} from "../../../components";
import { COLOURS } from "../../../shared";
import { CircleHelp } from "lucide-react";
import log from "../../../shared/utils/log";

export const MatterStateSelectionModal: React.FC<{
  closeModal: () => void;
  onConfirm: (hasMattersInMultipleStates: boolean) => void;
  region: string;
}> = ({ closeModal, onConfirm, region }) => {
  const [selectedMatterState, setSelectedMatterState] = useState<
    boolean | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  // Adjusted to accept an index and map it to a boolean
  const handleSelectOption = (index: number) => {
    const value = index === 1; // index 0 -> false, index 1 -> true
    log("the selected option is: ", value);
    setSelectedMatterState(value);
  };

  const handleGoBack = () => {
    log("Go back clicked");
    closeModal();
  };

  const handleSubmit = async () => {
    if (selectedMatterState === null) {
      console.error("No option selected for matter type states.");
      return;
    }
    setIsLoading(true);
    try {
      onConfirm(selectedMatterState);
    } catch (error) {
      console.error("Error during the process:", error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const isSubmitButtonDisabled = selectedMatterState === null;
  const additionalGBParagraphText =
    region === "GB" ? " (England/Scotland/Wales/Northern Ireland)." : ".";
  const stateOrCountry = region === "GB" ? "country" : "state";
  const statesOrCountries = region === "GB" ? "countries" : "states";

  return (
    <>
      <Modal
        backgroundColor={COLOURS.matterStateSelectionIconBackground}
        icon={
          <CircleHelp
            color={COLOURS.matterStateSelectionIconColor}
            strokeWidth={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        buttons={[
          {
            Button: StandardButton,
            label: "Cancel, go back",
            action: handleGoBack,
          },
          {
            Button: ({ ...props }) => (
              <SubmitButton
                {...props}
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
              />
            ),
            label: "Continue",
            action: handleSubmit,
          },
        ]}
        headingText={`Are you importing matters for ${statesOrCountries} other than the Firm's ${stateOrCountry}?`}
      >
        <ModalParagraphText>
          This will determine the Smokeball Matter Types available for mapping
          in the following steps{additionalGBParagraphText}
        </ModalParagraphText>
        <Checkbox
          id="noAdditionalMatterStates"
          name="selectedMatterStatesOption"
          value="no"
          checked={selectedMatterState === false}
          onChange={handleSelectOption}
          label="No"
          description={`I'm only importing matters for my Firm's ${stateOrCountry}.`}
          index={0} // index 0 maps to false
          type="radio"
          containerPadding="1rem 0"
          clickableContainer={true}
        />
        <Checkbox
          id="yesAdditionalMatterStates"
          name="selectedMatterStatesOption"
          value="yes"
          checked={selectedMatterState === true}
          onChange={handleSelectOption}
          label="Yes"
          description={`I'm importing matters for ${statesOrCountries} other than the Firm's ${stateOrCountry}.`}
          index={1} // index 1 maps to true
          type="radio"
          containerPadding="1rem 0rem 2rem 0rem"
          clickableContainer={true}
        />
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};
