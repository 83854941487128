import React, { useState } from "react";
import styled from "@emotion/styled";
import { COLOURS } from "../shared";

interface TooltipProps {
  position?: "top" | "bottom";
  justifyText?: boolean;
  message?: string;
  enabled?: boolean;
  children: React.ReactNode;
}

const TooltipContent = styled.div<{
  position?: "top" | "bottom";
  justifyText?: boolean;
  isVisible: boolean;
}>`
  position: absolute;
  align-self: stretch;
  min-width: 17rem;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  font-size: ${(props) => (props.theme.fontSize.small)};
  font-weight: 600;
  line-height: 150%;
  background-color: ${COLOURS.toolTipContentBackground};
  color: ${COLOURS.toolTipContentColor};
  text-align: ${({ justifyText }) => (justifyText ? "justify" : "left")};  
  white-space: pre-line;
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem;
  z-index: 1;
  left: 50%;
  transform: translateX(-100%);
  ${({ position }) => position === 'top' ? `bottom: 100%;` : `top: 80%;`}
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;

const TooltipWrapper = styled.div`
  position: relative;
`;

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  position,
  justifyText,
  message,
  enabled = true,
}) => {
  const [show, setShow] = useState(false);

  return (
    <TooltipWrapper
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      <TooltipContent
        isVisible={enabled && show}
        position={position}
        justifyText={justifyText}
      >
        {message}
      </TooltipContent>
    </TooltipWrapper>
  );
};
