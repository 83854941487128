import React from "react";
import ReactModal from "react-modal";
import styled from "@emotion/styled";
import { StandardButton } from "./buttons";
import { COLOURS } from "../shared";

interface ModalContainerProps {
  width?: string;
}

const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  width:${(props) => props.width || "50rem"};
  padding: 2.4rem;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  border-radius: 1.2rem;
  background: ${COLOURS.modalContainerBackground};
  margin: -2rem;
  line-height: 200%;
  font-size: ${(props) => props.theme.fontSize.subHeading};
`;

interface IconWrapperProps {
  backgroundColor?: string;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 2.8rem;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface HeadingInfoRowProps {
  lineHeight?: string;
}

export const HeadingInfoRow = styled.div<HeadingInfoRowProps>`
  align-self: stretch;
  color: ${COLOURS.modalHeadingInfoRowColor}
  font-size: ${(props) => props.theme.fontSize.subHeading};
  font-style: normal;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: ${(props) => props.lineHeight || "2.8rem"};
`;

export const ModalParagraphText = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 2rem;
  margin-bottom: 1rem;
  color: ${COLOURS.modalParagraphColor};
`

export const ConfirmButton = styled(StandardButton)`
  color: ${COLOURS.primaryButtonColor};
  background: ${(props) => props.background || COLOURS.primaryButtonBackground};
  border: 1px solid ${(props) => props.border || COLOURS.primaryButtonBorder};
`;

interface ModalProps {
  backgroundColor?: string;
  width?: string;
  closeAction?: () => void;
  buttons: {
    Button: React.ComponentType<any>;
    label: string;
    action: () => void;
  }[];
  children: React.ReactNode;
  icon: React.ReactNode;
  headingText?: string;
}

export const Modal: React.FC<ModalProps> = ({
  buttons,
  children,
  icon,
  headingText,
  backgroundColor = COLOURS.modalDefaultBackgroundColor,
  width,
}) => {
  return (
    <ReactModal
      isOpen={true}
      style={{
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
        content: {
          position: "relative",
          borderRadius: "1.2rem",
          margin: "0rem auto",
        },
      }}
    >
      <ModalContainer width={width}>
        <IconWrapper backgroundColor={backgroundColor}>{icon}</IconWrapper>
        {headingText && <HeadingInfoRow>{headingText}</HeadingInfoRow>}
        {children}
        <div
          style={{
            display: buttons.length === 1 ? "flex" : "grid",
            gridTemplateColumns: buttons.length === 2 ? "1fr 1fr" : "1fr",
            justifyContent: "space-between",
          }}
        >
          {buttons.map((button, index) => (
            <button.Button
              key={index}
              style={{
                width: buttons.length === 1 ? "100%" : "auto",
                marginRight: (buttons.length === 2 && index === 0) ? "2.0rem" : "0",
              }}
              onClick={button.action}
            >
              {button.label}
            </button.Button>
          ))}
        </div>
      </ModalContainer>
    </ReactModal>
  );
};
