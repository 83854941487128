import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { requestHelper } from "../shared/utils";

const cancelExistingMigration = async ({
  accountId,
  migrationSetId,
  token,  
}: {
  accountId: string;
  migrationSetId: string;
  token?: () => Promise<string>;
}): Promise<unknown> => {
  const endpoint = `/${parseInt(migrationSetId, 10)}/cancel`;
  await requestHelper(
    accountId,
    endpoint,
    "Failed to cancel the import.",
    "POST",
    token,
  );
  return undefined;
};

export const useCancelMigration = ({
  accountId,
  token,
}: {
  accountId: string;
  token?: () => Promise<string>;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Ensure token is always defined before using it
  if (!token) {
    throw new Error("Authentication token function is missing.");
  }

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (migrationSetId: string) =>
      cancelExistingMigration({
        accountId,
        migrationSetId,
        token,
      }),
    onSuccess: () => {
      // Clear the query to refetch fresh data after cancellation
      queryClient.clear();
    },
  });

  const handleCancelMigrationClick = async (migrationSetId: string) => {
    try {
      await mutateAsync(migrationSetId);
      navigate("/embedded/jumpstart");
    } catch (error) {
      console.error("Error cancelling import: ", error);
    }
  };

  return {
    handleCancelMigrationClick,
    isLoading,
  };
};
