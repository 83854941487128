import React from "react";
import { QueryObserverResult } from "react-query";
import {
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import { getTemplateKey } from "../../functions/workflowFunctions";
import FolderIcon from "../folderIcon";

interface CardMatterProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardMatter: React.FC<CardMatterProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      region={region}
      image={<FolderIcon />}
      importType="matter"
      templateKey={getTemplateKey("matter", region)}
      fileName="matter.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "matter")}
      headerText={ImportTypes.Matters}
      description="Matters can only be uploaded once Contacts are uploaded and valid. Matter Types are then extracted for configuration."
      cardState={cardsState["matter"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Matters can only be uploaded once Contacts are uploaded and valid."
    />
  );
};
