import type { Theme } from "@emotion/react";

import { interStack } from "../fonts";

export type Colour = {
  base: string;
  accent: string;
  highlight: string;
  overlay: string;
};

declare module "@emotion/react" {
  export interface Theme {
    baseUnit: number;
    baseFontSize: string;
    fonts: {
      title: string;
      body: string;
      small: string;
    };
    fontSize: {
      heading: string;
      subHeading: string;
      emphasis: string;
      base: string;
      small: string;
      xSmall: string;
      xxSmall: string;
    };
    palette: {
      background: Colour;
      foreground: Colour;
      primary: Colour;
      secondary: Colour;
      active: Colour;
      danger: Colour;
      warning: Colour;
      success: Colour;
      default: Colour;
      disabled: Colour;
    };
    breakPoints: {
      mobile: number;
      tablet: number;
      readable: number;
      medium: number;
      maximum: number;
    };
  }
}

const bg = "#FEFEFE";
const fg = "#232E41";

export const defaultTheme: Theme = {
  baseUnit: 1,
  baseFontSize: "62.5%",
  fonts: {
    title: interStack,
    body: interStack,
    small: interStack,
  },
  fontSize: {
    heading: "2.2rem",
    subHeading: "1.8rem",
    emphasis: "1.6rem",
    base: "1.4rem",
    small: "1.2rem",
    xSmall: "1.1rem",
    xxSmall: "1rem",
  },
  palette: {
    background: {
      base: bg,
      accent: "#F3F4F6",
      highlight: "#DEDEDE",
      overlay: fg,
    },
    foreground: {
      base: fg,
      accent: "#304860",
      highlight: "#4A637C",
      overlay: bg,
    },
    primary: {
      base: "#0EA5E9",
      accent: "#7DD3FC",
      highlight: "#7DD3FC",
      overlay: bg,
    },
    secondary: {
      base: "#075985",
      accent: "#0284C7",
      highlight: "#0284C7",
      overlay: bg,
    },
    active: {
      base: "#007082",
      accent: "#8CA2AF",
      highlight: "#BFCCD2",
      overlay: bg,
    },
    danger: {
      base: "#BE123C",
      accent: "#F43F5E",
      highlight: "#F43F5E",
      overlay: bg,
    },
    warning: {
      base: "#F2B714",
      accent: "#F0D395",
      highlight: "#F7E4C6",
      overlay: bg,
    },
    success: {
      base: "#15803D",
      accent: "#22C55E",
      highlight: "#22C55E",
      overlay: bg,
    },
    default: {
      base: "#232E41",
      accent: "#304860",
      highlight: "#304860",
      overlay: bg,
    },
    disabled: {
      base: "#BCBCBC",
      accent: "#DEDEDE",
      highlight: "#DEDEDE",
      overlay: "#888888",
    },
  },
  breakPoints: {
    mobile: 400,
    tablet: 680,
    readable: 800,
    medium: 900,
    maximum: 1200,
  },
};
