import { ImportTypes } from "../types";
import { PRODUCT_TIERS } from "./constants";

// Helper function to check if a migration set import type is in the old format (pre-data import type selection screen)
// Old format: "staff;contact;matter" (semicolon-separated string) or an empty string
// New format: '["Staff","Contact","Matter"]'
export const isOldFormatImportType = (importTypeString: string): boolean => {
  return !importTypeString || !importTypeString.startsWith('["'); // Checks if it's not a properly formatted JSON array with strings
};

// Helper function to get allowed import types based on region/tier for old formats
// If productId is empty, this function returns only basic import types (excluding memos, tasks, safe custody packets/docs)
export const getAllowedImportTypes = (
  region: string,
  productId: string,
): string[] => {
  const allowedImportTypes = [
    ImportTypes.Staff,
    ImportTypes.Contacts,
    ImportTypes.Matters,
    ImportTypes.MatterTypes,
    ImportTypes.OpeningBalances,
    ImportTypes.ActivityTypes,
    ImportTypes.UnbilledTimeAndFees,
    ImportTypes.UnbilledDisbursements,
  ];

  // If productId is empty, return only the basic import types
  if (!productId) {
    return allowedImportTypes;
  }

  // Add memos and tasks if productId is BOOST, GROW, or PROSPER
  if (
    productId === PRODUCT_TIERS.BOOST ||
    productId === PRODUCT_TIERS.GROW ||
    productId === PRODUCT_TIERS.PROSPER
  ) {
    allowedImportTypes.push(ImportTypes.Memos, ImportTypes.Tasks);
  }

  // Add Safe Custody Packets and Documents if productId is GROW or PROSPER and region is not US
  if (
    (productId === PRODUCT_TIERS.GROW || productId === PRODUCT_TIERS.PROSPER) &&
    region !== "US"
  ) {
    allowedImportTypes.push(
      ImportTypes.SafeCustodyPackets,
      ImportTypes.SafeCustodyDocuments,
    );
  }

  return allowedImportTypes;
};
