import { useEffect, useState } from "react";
import { Firm, UseSDKApiOptions } from "../types";
import { SDKError, logError } from "../utils/errors";

export const useFirm = ({ client, isInit, observe }: UseSDKApiOptions) => {
  const [loading, setLoading] = useState(true);
  const [currentFirm, setCurrentFirm] = useState<Firm | undefined>();
  
  const shouldObserve = observe?.includes("firm");

  useEffect(() => {
    if (isInit && client) {
      setLoading(true);

      // Fetch the firm details
      client.firm
        .get()
        .then((firm) => {
          setCurrentFirm(firm);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          logError(new SDKError(err, { action: "firm.get" }));
        });

      // Observe changes if required
      if (shouldObserve) {
        client.firm.observe((firm) => setCurrentFirm(firm));
      }
    }
  }, [client, isInit, shouldObserve]);

  if (!isInit || !client) return undefined;

  return {
    get: async () => {
      try {
        return await client.firm.get();
      } catch (error) {
        throw new SDKError(error, { action: "firm.get" });
      }
    },
    current: currentFirm,
    loading,
  };
};
