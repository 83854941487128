import styled from "@emotion/styled";

export const Row = styled.div<{ maxHeight?: number }>`
  height: auto;
  max-height: ${(props) => props.maxHeight}rem;
  margin-left: 6.4rem;
  margin-right: 6.4rem;
  @media (max-width: 1511px) {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
`;
