import React from "react";
import styled from "@emotion/styled";
import { Spinner } from "../components";
import { COLOURS } from "../shared";

const CenteredContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ transparent }) => transparent ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 1)'};
  color: ${COLOURS.loaderColor};
  z-index: 999;
`;

interface Props {
  isLoading?: boolean;
  transparent?: boolean;
}

export const Loader: React.FC<Props> = ({ isLoading = true, transparent = true }: Props) => {
  return (
    <CenteredContainer transparent = {transparent}>
      <Spinner
        color={COLOURS.loaderColor}
        loading={isLoading}
        size={4}
        aria-label="Loading"
        data-testid="loader"
      />
    </CenteredContainer>
  );
};
