import { useEffect, useState } from "react";

import { v4, extractCognitoToken } from "../utils";

import { useAuth } from "./smokeballSDK.local.auth";
import { useHost } from "./smokeballSDK.local.host";
import { useFirm } from "./smokeballSDK.local.firm";
import { useStaff } from "./smokeballSDK.local.staff";
import { getLocalSmokeballAppContext } from "../smokeballApp.utils.local";
import { SmokeballAppClient } from "./smokeballSDK";
import { UseSmokeballAppOptions } from "../types";
import log from "../utils/log";

const sessionId = v4();

export const useLocalClient = (
  options?: UseSmokeballAppOptions
): SmokeballAppClient => {
  const localContext = getLocalSmokeballAppContext();
  const [context, setContext] = useState<
    NonNullable<SmokeballAppClient["context"]>
  >({
    sessionId,
    firmId: localContext?.firmId || "",
    userId: localContext?.userId || "",
    region: {
      code: "local",
      name: "local",
    },
  });

  useEffect(() => {
    (async () => {
      //eslint-disable-next-line react-hooks/rules-of-hooks
      const token = await useAuth().token(); // this is not a hook, just named with `use`
      log("token: ", token);

      if (token) {
        const extracted = token && (await extractCognitoToken(token));
        setContext({
          sessionId,
          firmId:
            (extracted && (extracted as any)["custom:smokeball:firmId"]) ||
            localContext?.firmId ||
            "",
          userId:
            (extracted && extracted.username) || localContext?.userId || "",
          region: {
            code: "local",
            name: "local",
          },
        });
      }
    })().catch(console.error);
  }, [localContext?.firmId, localContext?.userId]);

  const localApiOptions = { context, observe: options?.observe };

  return {
    hostType: "local",
    context,
    auth: useAuth(),
    firm: useFirm(localApiOptions),
    staff: useStaff(localApiOptions),
    host: useHost(),
  };
};

export * from "../smokeballApp.utils.local";
