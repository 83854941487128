import { useState } from "react";
import { PageBox, Row, StyledIconButton } from "../../../components";
import styled from "@emotion/styled";
import { COLOURS } from "../../../shared";
import { Plus, Zap } from "lucide-react";
import { ImportTypeSelectionModal } from "./importTypeSelection";

interface NewMigrationProps {
  handleConfirm: (importTypes: string[]) => void;
  productId: string | undefined;
}

export const NewMigration: React.FC<NewMigrationProps> = ({ handleConfirm, productId }) => {
  const [showImportModal, setShowImportModal] = useState(false);

  const IconWrapper = styled.div`
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: ${COLOURS.newMigrationIconWrapperBackground};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const StyledParagraph = styled.p`
    font-size: ${(props) => props.theme.fontSize.emphasis};
  `;

  // Function to handle opening of the modal
  const handleNewImportClick = () => {
    setShowImportModal(true); // Open the modal
  };

  return (
    <>
      <PageBox isExistingMigration={false} padding="6.4rem">
        <Row maxHeight={10}>
          <IconWrapper>
            <Zap
              color={COLOURS.newMigrationIconColor}
              strokeWidth={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </IconWrapper>
        </Row>
        <Row maxHeight={5}>
          <h1>Start your first import</h1>
        </Row>
        <Row>
          <StyledParagraph>
            Get a quick start to importing Contact and Matter data. You can
            start and stop this process at any time but once you have submitted
            an import it is irreversible.
          </StyledParagraph>
        </Row>
        <Row maxHeight={20}>
          <StyledIconButton
            disabled={false}
            text="New Import"
            icon={<Plus size={16} className="icon" />}
            onClick={handleNewImportClick} // Open modal on click
          />
        </Row>
      </PageBox>

      {/* Show the modal when 'New Import' is clicked */}
      {showImportModal && (
        <ImportTypeSelectionModal
          closeModal={() => setShowImportModal(false)}
          onConfirm={handleConfirm}  // Pass handleConfirm to modal
          productId={productId}
          titleText="New Import"
          subtitleText="Select items to include in your import"
          existingImportTypes={[]}
        />
      )}
    </>
  );
};
