import React from "react";
import styled from "@emotion/styled";
import check from "../images/check.svg";
import { COLOURS } from "../shared";

interface CheckboxProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (index: number) => void;
  label: string;
  labelColor?: string;
  labelFontSize?: string;
  description?: string;
  descriptionColor?: string;
  descriptionFontSize?: string;
  containerPadding?: string;
  index: number;
  type: "checkbox" | "radio";
  shouldStopPropagation?: boolean;
  clickableContainer?: boolean;
}

const CheckboxContainer = styled.span<{ containerPadding?: string }>`
  padding: ${(props) => props.containerPadding || "0"};
  display: flex;
  flex-direction: column; /* Stack label/description vertically */
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  appearance: none;
  background-color: ${COLOURS.modalRadioButtonInputBackground};
  border: 1px solid ${COLOURS.modalRadioButtonInputBorder};
  border-radius: 0.6rem;
  padding: 0.6rem;
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;

  /* Apply transitions outside of &:checked */
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease;

  &:checked {
    background-color: ${COLOURS.modalRadioCheckedButtonInputBackground};
    border-color: ${COLOURS.modalRadioCheckedButtonInputBorder};

    /* Checkmark display */
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${check});
      background-size: 80% 80%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row; /* Keep checkbox and label on the same line */
  align-items: center; /* Vertically center checkbox and label */
`;

const LabelText = styled.span<{ labelColor?: string; labelFontSize?: string }>`
  font-size: ${(props) => props.labelFontSize || props.theme.fontSize.emphasis};
  font-weight: 600;
  color: ${(props) => props.labelColor || COLOURS.standardBlackText};
`;

const DescriptionText = styled.span<{
  descriptionColor?: string;
  descriptionFontSize?: string;
}>`
  font-size: ${(props) =>
    props.descriptionFontSize || props.theme.fontSize.emphasis};
  font-weight: 400;
  color: ${(props) => props.descriptionColor || COLOURS.modalParagraphColor};
  margin-left: 3.6rem;
  line-height: 2rem;
`;

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
  labelColor,
  labelFontSize,
  description,
  descriptionColor,
  descriptionFontSize,
  containerPadding,
  index,
  type,
  shouldStopPropagation,
  clickableContainer = false,
}) => {
  return (
    <CheckboxContainer
      containerPadding={containerPadding}
      onClick={clickableContainer ? () => onChange(index) : undefined}
    >
      <LabelContainer>
        <CheckboxInput
          type={type}
          id={id}
          name={name}
          value={value}
          checked={checked}
          onClick={
            shouldStopPropagation ? (e) => e.stopPropagation() : undefined
          }
          onChange={() => onChange(index)}
        />
        <LabelText labelColor={labelColor} labelFontSize={labelFontSize}>
          {label}
        </LabelText>
      </LabelContainer>
      {description && (
        <DescriptionText
          descriptionColor={descriptionColor}
          descriptionFontSize={descriptionFontSize}
        >
          {description}
        </DescriptionText>
      )}
    </CheckboxContainer>
  );
};
