import styled from 'styled-components';
import { COLOURS } from '../shared';

interface StyledImageProps {
 padding?: string;
 flexShrink?: number;
 borderRadius?: string;
 border?: string;
 background?: string;
}

export const StyledImage = styled.img<StyledImageProps>`
 display: flex;
 justify-content: center;
 align-items: center; 
 width: ${props => props.width || '4rem'};
 height: ${props => props.height || '4rem'};
 padding: ${props => props.padding || '0.125rem'};
 flex-shrink: ${props => props.flexShrink || 0};
 border-radius: ${props => props.borderRadius || '2rem'};
 border: ${props => props.border || `0.5rem solid ${COLOURS.styledImageDefaultBorder}`};
 background: ${props => props.background || COLOURS.styledImageDefaultBackground};
 margin-right: auto;
`;
