import {
  ImportType,
  InvoiceBalances,
  MatterOpeningBalances,
  MigrationSetImport,
  TemplateKey,
  UnbilledExpenseBalances,
  UnbilledTimeFeeBalances,
} from "../shared";
import { formatCurrency } from "../shared/utils";

export function getDefaultCardState(
  isReady: boolean = true,
  isDisabled: boolean = false,
) {
  return {
    hasUploaded: false,
    hasWarnings: false,
    hasErrors: false,
    uploadWarningCount: 0,
    uploadErrorCount: 0,
    uploadTotalRecordCount: 0,
    isSuccess: false,
    isReady: isReady,
    isDisabled: isDisabled,
    needsRevalidation: false,
  };
}

export function getTemplateKey(
  importType: ImportType,
  region: string,
): TemplateKey {
  switch (region) {
    case "US":
      switch (importType) {
        case "contact":
          return "contactImportUS";
        case "matter":
          return "matterImportUS";
        case "matterType":
          return "matterTypeImportUS";
        case "matterOpeningBalance":
          return "matterOpeningBalanceImportUS";
        case "unbilledTimeAndFee":
          return "unbilledTimeAndFeeImportUS";
        case "unbilledExpense":
          return "unbilledExpenseImportUS";
        case "invoice":
          return "invoiceImportUS";
        case "invoiceItem":
          return "invoiceItemImportUS";
        default:
          throw new Error(
            `Unsupported import type: ${importType} for region: ${region}`,
          );
      }
    case "AU":
      switch (importType) {
        case "contact":
          return "contactImportAU";
        case "matter":
          return "matterImportAU";
        case "matterType":
          return "matterTypeImportAU";
        case "matterOpeningBalance":
          return "matterOpeningBalanceImportAU";
        case "unbilledTimeAndFee":
          return "unbilledTimeAndFeeImportAU";
        case "unbilledExpense":
          return "unbilledExpenseImportAU";
        case "invoice":
          return "invoiceImportAU";
        case "invoiceItem":
          return "invoiceItemImportAU";
        default:
          throw new Error(
            `Unsupported import type: ${importType} for region: ${region}`,
          );
      }
    case "GB":
      switch (importType) {
        case "contact":
          return "contactImportGB";
        case "matter":
          return "matterImportGB";
        case "matterType":
          return "matterTypeImportGB";
        case "matterOpeningBalance":
          return "matterOpeningBalanceImportGB";
        case "unbilledTimeAndFee":
          return "unbilledTimeAndFeeImportGB";
        case "unbilledExpense":
          return "unbilledExpenseImportGB";
        case "invoice":
          return "invoiceImportGB";
        case "invoiceItem":
          return "invoiceItemImportGB";
        default:
          throw new Error(
            `Unsupported import type: ${importType} for region: ${region}`,
          );
      }
    default:
      throw new Error(`Unsupported region: ${region}`);
  }
}

export function getMatterOpeningBalancesDescription(
  region: string,
  migrationSetImport: MigrationSetImport | undefined,
): {
  heading?: string;
  details?: { label: string; value: string }[];
  simpleText?: string;
} {
  if (!migrationSetImport) {
    switch (region) {
      case "AU":
        return {
          simpleText:
            "Import Credit and Trust balances. Matters must be uploaded and valid before Opening Balances can be added.",
        };
      case "GB":
        return {
          simpleText:
            "Import Credit and Client account balances. Matters must be uploaded and valid before Opening Balances can be added.",
        };
      case "US":
        return {
          simpleText:
            "Import Operating, Credit, and Trust balances. Matters must be uploaded and valid before Opening Balances can be added.",
        };
      default:
        return {
          simpleText:
            "Import Operating, Credit, and Trust balances. Matters must be uploaded and valid before Opening Balances can be added.",
        };
    }
  }

  if (migrationSetImport.hasUploaded) {
    const balances = migrationSetImport.balances as MatterOpeningBalances;
    const heading = "Total account balances:";
    const regionDetails = {
      AU: [
        { label: "Credit:", value: formatCurrency(balances?.credit, "AU") },
        { label: "Trust:", value: formatCurrency(balances?.trust, "AU") },
      ],
      GB: [
        { label: "Credit:", value: formatCurrency(balances?.credit, "GB") },
        { label: "Client:", value: formatCurrency(balances?.trust, "GB") },
      ],
      US: [
        { label: "Credit:", value: formatCurrency(balances?.credit, "US") },
        { label: "Trust:", value: formatCurrency(balances?.trust, "US") },
        {
          label: "Operating:",
          value: formatCurrency(balances?.operating, "US"),
        },
      ],
    };

    const details = regionDetails[region as keyof typeof regionDetails] || [];
    return { heading, details };
  } else {
    return {
      simpleText:
        "Matters must be uploaded and valid before Opening Balances can be added.",
    };
  }
}

export function getActivitiesDescription(region: string): {
  heading?: string;
  simpleText?: string;
} {
  switch (region) {
    case "AU":
      return {
        simpleText:
          "Imported Activity Types can be used for time, fee, or disbursement entries that are created once you are up and running with Smokeball. Activity Types are not required in order to import Unbilled Entries.",
      };
    case "GB":
      return {
        simpleText:
          "Imported Activity Types can be used for time, fee, or cost recovery entries that are created once you are up and running with Smokeball. Activity Types are not required in order to import Unbilled Entries.",
      };
    case "US":
      return {
        simpleText:
          "Imported Activity Types can be used for time, fee, or expense entries that are created once you are up and running with Smokeball. Activity Types are not required in order to import Unbilled Entries.",
      };
    default:
      return {
        simpleText:
          "Imported Activity Types can be used for time, fee, or expense entries that are created once you are up and running with Smokeball. Activity Types are not required in order to import Unbilled Entries.",
      };
  }
}

export function getUnbilledTimeAndFeesDescription(
  region: string,
  migrationSetImport: MigrationSetImport | undefined,
): {
  heading?: string;
  details?: { label: string; value: string }[];
  simpleText?: string;
} {
  if (!migrationSetImport) {
    switch (region) {
      case "AU":
        return {
          simpleText:
            "Import unbilled time/fee entries. The default GST rate should be configured in the settings of your Smokeball account before uploading unbilled entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      case "GB":
        return {
          simpleText:
            "Import unbilled time/fee entries. The default VAT rate should be configured in the settings of your Smokeball account before uploading unbilled entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      case "US":
        return {
          simpleText:
            "Import unbilled time/fee entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      default:
        return {
          simpleText:
            "Import unbilled time/fee entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
    }
  }

  if (migrationSetImport.hasUploaded) {
    const balances = migrationSetImport.balances as UnbilledTimeFeeBalances;
    const heading = "Total amounts:";
    const regionDetails = {
      AU: [
        { label: "Exc. GST:", value: formatCurrency(balances?.totalex, "AU") },
        { label: "Inc. GST:", value: formatCurrency(balances?.totalinc, "AU") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
      GB: [
        { label: "Exc. VAT:", value: formatCurrency(balances?.totalex, "GB") },
        { label: "Inc. VAT:", value: formatCurrency(balances?.totalinc, "GB") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
      US: [
        { label: "", value: formatCurrency(balances?.totalex, "US") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
    };
    const details = regionDetails[region as keyof typeof regionDetails] || [];
    return { heading, details };
  } else {
    return {
      simpleText:
        "Import unbilled time/fee entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
    };
  }
}

export function getUnbilledTimeAndFeesTooltip(region: string): string {
  switch (region) {
    case "AU":
      return "Set the default GST rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Unbilled Entries can be added.";
    case "GB":
      return "Set the default VAT rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Unbilled Entries can be added.";
    case "US":
      return "Matters must be uploaded and valid before Unbilled Entries can be added.";
    default:
      return "Matters must be uploaded and valid before Unbilled Entries can be added.";
  }
}

export function getUnbilledExpensesDescription(
  region: string,
  migrationSetImport: MigrationSetImport | undefined,
): {
  heading?: string;
  details?: { label: string; value: string }[];
  simpleText?: string;
} {
  if (!migrationSetImport) {
    switch (region) {
      case "AU":
        return {
          simpleText:
            "Import unbilled disbursement entries. The default GST rate should be configured in the settings of your Smokeball account before uploading unbilled entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      case "GB":
        return {
          simpleText:
            "Import unbilled cost recovery entries. The default VAT rate should be configured in the settings of your Smokeball account before uploading unbilled entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      case "US":
        return {
          simpleText:
            "Import unbilled expense entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
      default:
        return {
          simpleText:
            "Import unbilled expense entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
        };
    }
  }

  if (migrationSetImport.hasUploaded) {
    const balances = migrationSetImport.balances as UnbilledExpenseBalances;
    const heading = "Total amounts:";
    const regionDetails = {
      AU: [
        { label: "Exc. GST:", value: formatCurrency(balances?.totalex, "AU") },
        { label: "Inc. GST:", value: formatCurrency(balances?.totalinc, "AU") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
      GB: [
        { label: "Exc. VAT:", value: formatCurrency(balances?.totalex, "GB") },
        { label: "Inc. VAT:", value: formatCurrency(balances?.totalinc, "GB") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
      US: [
        { label: "", value: formatCurrency(balances?.totalex, "US") },
        { label: "", value: "" },
        { label: "(inc. non-billable and written off entries)", value: "" },
      ],
    };
    const details = regionDetails[region as keyof typeof regionDetails] || [];
    return { heading, details };
  } else {
    return {
      simpleText:
        "Import unbilled expense entries. Matters must be uploaded and valid before Unbilled Entries can be added.",
    };
  }
}

export function getUnbilledExpensesTooltip(region: string): string {
  switch (region) {
    case "AU":
      return "Set the default GST rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Unbilled Entries can be added.";
    case "GB":
      return "Set the default VAT rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Unbilled Entries can be added.";
    case "US":
      return "Matters must be uploaded and valid before Unbilled Entries can be added.";
    default:
      return "Matters must be uploaded and valid before Unbilled Entries can be added.";
  }
}

export function getInvoicesDescription(
  region: string,
  migrationSetImport: MigrationSetImport | undefined,
): {
  heading?: string;
  details?: { label: string; value: string }[];
  simpleText?: string;
} {
  if (!migrationSetImport) {
    switch (region) {
      case "AU":
        return {
          simpleText:
            "Import unpaid and part paid invoices. Paid invoices cannot be imported. The default GST rate should be configured in the settings of your Smokeball account before uploading invoices. Matters must be uploaded and valid before Invoices can be added.",
        };
      case "GB":
        return {
          simpleText:
            "Import unpaid and part paid invoices. Paid invoices cannot be imported. The default VAT rate should be configured in the settings of your Smokeball account before uploading invoices. Matters must be uploaded and valid before Invoices can be added.",
        };
      case "US":
        return {
          simpleText:
            "Import unpaid and part paid invoices. Paid invoices cannot be imported. Matters must be uploaded and valid before Invoices can be added.",
        };
      default:
        return {
          simpleText:
            "Import unpaid and part paid invoices. Paid invoices cannot be imported. Matters must be uploaded and valid before Invoices can be added.",
        };
    }
  }

  if (migrationSetImport.hasUploaded) {
    const balances = migrationSetImport.balances as InvoiceBalances;
    const regionDetails = {
      AU: [
        {
          label: "Total (Inc. GST):",
          value: formatCurrency(balances?.totalinc, "AU"),
        },
        {
          label: "Amount Due (Inc. GST):",
          value: formatCurrency(balances?.dueinc, "AU"),
        },
        {
          label: "Fee Total (Exc./Inc. GST):",
          value: `${formatCurrency(balances?.feeex, "AU")}/${formatCurrency(
            balances?.feeinc,
            "AU"
          )}`,
        },
        {
          label: "Disb Total (Exc./Inc. GST):",
          value: `${formatCurrency(balances?.expenseex, "AU")}/${formatCurrency(
            balances?.expenseinc,
            "AU"
          )}`,
        },
      ],
      GB: [
        {
          label: "Total (Inc. VAT):",
          value: formatCurrency(balances?.totalinc, "GB"),
        },
        {
          label: "Amount Due (Inc. VAT):",
          value: formatCurrency(balances?.dueinc, "GB"),
        },
        {
          label: "Fee Total (Exc./Inc. VAT):",
          value: `${formatCurrency(balances?.feeex, "GB")}/${formatCurrency(
            balances?.feeinc,
            "GB"
          )}`,
        },
        {
          label: "Cost Rec. Total (Exc./Inc. VAT):",
          value: `${formatCurrency(balances?.expenseex, "GB")}/${formatCurrency(
            balances?.expenseinc,
            "GB"
          )}`,
        },
      ],
      US: [
        { label: "Total:", value: formatCurrency(balances?.totalinc, "US") },
        { label: "Amount Due:", value: formatCurrency(balances?.dueinc, "US") },
        { label: "Fee Total:", value: formatCurrency(balances?.feeinc, "US") },
        {
          label: "Expense Total:",
          value: formatCurrency(balances?.expenseinc, "US"),
        },
      ],
    };
    const details = regionDetails[region as keyof typeof regionDetails] || [];
    return { details };
  } else {
    return {
      simpleText:
        "Import unpaid and part paid invoices. Paid invoices cannot be imported. Matters must be uploaded and valid before Invoices can be added.",
    };
  }
}

export function getInvoicesTooltip(region: string): string {
  switch (region) {
    case "AU":
      return "Set the default GST rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Invoices can be added.";
    case "GB":
      return "Set the default VAT rate in the settings of your Smokeball account before uploading. Matters must be uploaded and valid before Invoices can be added.";
    case "US":
      return "Matters must be uploaded and valid before Invoices can be added.";
    default:
      return "Matters must be uploaded and valid before Invoices can be added.";
  }
}
