import React from "react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import { getTemplateKey } from "../../functions/workflowFunctions";
import { ListCollapse } from "lucide-react";

interface CardInvoiceItemProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardInvoiceItem: React.FC<CardInvoiceItemProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      image={
        <ListCollapse
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="invoiceItem"
      templateKey={getTemplateKey("invoiceItem", region)}
      fileName="invoiceItem.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "invoiceItem",
      )}
      headerText={ImportTypes.InvoiceItems}
      description=""
      cardState={cardsState["invoiceItem"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage=""
      region={region}
    />
  );
};
