import { useEffect, useState } from "react";

export const useRegion = (accountBillingCountryCode?: string) => {
  const [region, setRegion] = useState<string>("Not set");

  useEffect(() => {
    if (accountBillingCountryCode) {
      setRegion(accountBillingCountryCode);
    } else {
      setRegion("Not set"); // Default so that a region error does not occur prior to setting actual region
    }
  }, [accountBillingCountryCode]);

  return region;
};

export const getRegionFromURL = (): "AU" | "US" | "GB" | "Unknown" => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "AU";
  } else if (hostname.includes("smokeball.com.au")) {
    return "AU";
  } else if (hostname.includes("smokeball.com")) {
    return "US";
  } else if (hostname.includes("smokeball.co.uk")) {
    return "GB";
  } else {
    return "Unknown";
  }
};

