import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { requestHelper } from "../shared/utils";

const submitExistingMigration = async ({
  accountId,
  migrationSetId,
  token,
}: {
  accountId: string;
  migrationSetId: string;
  token?: () => Promise<string>;
}): Promise<unknown> => {
  const endpoint = `/${parseInt(migrationSetId, 10)}/submit?autoLoad=true`;
  await requestHelper(
    accountId,
    endpoint,
    "Failed to submit the import.",
    "POST",
    token,
  );

  return undefined;
};

export const useSubmitMigration = ({
  accountId,
  token,
}: {
  accountId: string;
  token?: () => Promise<string>;
}) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(submitExistingMigration);

  const handleSubmitMigrationClick = async (migrationSetId: string) => {
    try {
      await mutateAsync({ accountId, migrationSetId, token });
      navigate("/embedded/jumpstart");
    } catch (error) {
      console.error("Error submitting import: ", error);
    }
  };

  return {
    handleSubmitMigrationClick,
    isLoading,
  };
};
