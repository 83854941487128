import React from "react";
import {
  Loader,
  Modal,
  ModalParagraphText,
  StandardButton,
  SubmitButton,
} from "../../../components";
import { Zap } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { COLOURS, useSmokeballApp } from "../../../shared";
import { useSubmitMigration } from "../../../hooks";
import log from "../../../shared/utils/log";

export const SubmitMigrationModal: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth, context } = useSmokeballApp();
  const { handleSubmitMigrationClick, isLoading } = useSubmitMigration({
    accountId: context?.firmId || "",
    token: auth?.token,
  });

  const handleGoBack = () => {
    log("Go back clicked");
    closeModal();
  };

  const handleSubmit = async () => {
    if (!id) {
      console.error("Import ID is missing.");
      return;
    }
    try {
      await handleSubmitMigrationClick(id);
      navigate("/embedded/jumpstart");
    } catch (error) {
      console.error("Error submiting import:", error);
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <Modal
        icon={
          <Zap
            color={COLOURS.submitModalIconColor}
            strokeWidth={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        buttons={[
          {
            Button: StandardButton,
            label: "No, go back",
            action: handleGoBack,
          },
          {
            Button: SubmitButton,
            label: "Yes, import",
            action: handleSubmit,
          },
        ]}
        headingText={"Are you sure you want to import all uploaded data?"}
      >
        <ModalParagraphText>
          The import is irreversible.
          <br />
          <br />
          Are you sure you want to import all uploaded data along with any
          outstanding warnings to Smokeball?
        </ModalParagraphText>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};
