
export const pluralWords = Object.freeze({
  // card types
  STAFF: "Staff",
  ENTRY: "Entries",
});

export const singleWords = Object.freeze({
  // card types
  STAFF: "Staff",
  ENTRY: "Entry",
}); 

// Function to get region-specific expense header
export const getRegionSpecificExpenseHeader = (region: string): string => {
  switch (region) {
    case "AU":
      return "Unbilled Disbursements";
    case "GB":
      return "Unbilled Cost Recoveries";
    case "US":
      return "Unbilled Expenses";
    default:
      return "Unbilled Expenses"; // default to US if region is not recognised
  }
};

// Function to get region-specific summary expense header
export const getRegionSpecificSummaryExpenseHeader = (region: string): string => {
  switch (region) {
    case "AU":
      return "Disbursements";
    case "GB":
      return "Cost Recoveries";
    case "US":
      return "Expenses";
    default:
      return "Expenses"; // default to US if region is not recognised
  }
};

export const PRODUCT_TIERS = Object.freeze({
  BILL: "SMK001",
  BOOST: "SMK004",
  GROW: "SMK002",
  PROSPER: "SMK003",
});