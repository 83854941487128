import { pluralWords, singleWords } from "./constants";

// Function to get the singular form of a headerText
export const getSingularForm = (headerText: string): string => {
  // If the headerText exists in singleWords, return the corresponding singular form
  if (headerText in singleWords) {
    return singleWords[headerText as keyof typeof singleWords];
  }

  // For regular cases, just remove 's' at the end (naive approach)
  return headerText.endsWith("s") ? headerText.slice(0, -1) : headerText;
};

// Function to format and pluralise text based on count and header type
export const formatWithPluralise = ({
  count,
  headerText,
}: {
  count: number;
  headerText: keyof typeof pluralWords | keyof typeof singleWords | string;
}): string => {
  // Convert headerText to uppercase for comparison
  const upperHeaderText = headerText.toUpperCase();

  // Check for special cases using singleWords and pluralWords
  if (upperHeaderText in singleWords || upperHeaderText in pluralWords) {
    const singularForm = singleWords[upperHeaderText as keyof typeof singleWords];
    const pluralForm = pluralWords[upperHeaderText as keyof typeof pluralWords];
    
    // Return based on count
    return `${count.toLocaleString()} ${count === 1 ? singularForm : pluralForm}`;
  }

  //Fallback for generic pluralisation: add 's' if count > 1
  return `${count.toLocaleString()} ${headerText}${count === 1 ? "" : "s"}`;
};
