import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { requestHelper, UnauthorizedError } from "../shared/utils";
import { MigrationSet, useSmokeballApp } from "../shared";
import log from "../shared/utils/log";

const updateImportTypes = async ({
    accountId,
    token,
    migrationSetId,
    importTypes,
  }: {
    accountId: string;
    token?: () => Promise<string>;
    migrationSetId: number;
    importTypes: string[];
  }): Promise<MigrationSet> => {
    // Join the import types into a comma-separated string
    const importTypesQuery = importTypes.join(",");
    const body = { importType: importTypesQuery }; 
    log("Request Body: ", JSON.stringify(body));
  
    // Construct the endpoint with query parameters
    const endpoint = `/${migrationSetId}`;
    log("Endpoint: ", endpoint);
  
    const data = await requestHelper(
      accountId,
      endpoint,
      "Failed to update import types.",
      "PATCH",
      token,
      body,
    );
    log("Updated import data: ", data);
  
    return data; // Return the full dataset
  };
  
  export const useUpdateMigration = () => {
    const { auth, context } = useSmokeballApp();
    const { mutateAsync, isLoading } = useMutation(updateImportTypes);
    const accountId = context?.firmId || "";
    const token = auth?.token;
    const queryClient = useQueryClient();

    const [isUnauthorized, setIsUnauthorized] = useState(false);
  
    const handleUpdateMigrationClick = async (migrationSetId: number, updatedImportTypes: string[]) => {
      try {
        const result = await mutateAsync({
          accountId,
          token,
          migrationSetId,
          importTypes: updatedImportTypes,
        });
        setIsUnauthorized(false); // Reset on success
        queryClient.invalidateQueries(["migrations", accountId]);
        log("Updated import types: ", updatedImportTypes);
        return result; // Return the full updated data set
      } catch (error) {
        if (error instanceof UnauthorizedError) {
            setIsUnauthorized(true);
          log("Unauthorized error during update.");
          return null; // Return null on UnauthorizedError
        }
        throw error;
      }
    };
  
    return {
      handleUpdateMigrationClick,
      isLoading,
      isUnauthorized,
    };
  };
