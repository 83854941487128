import { css, Theme } from "@emotion/react";
import { transparentize } from "polished";

export type ButtonProps = {
  color?: string;
  palette?: keyof Theme["palette"];
  active?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  dot?: keyof Theme["palette"];
  background?: string;
  border?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  padding?: string;
};

export const buttonColor = (props: ButtonProps & { theme: Theme }): string => {
  if (props.disabled) return props.theme.palette.disabled.base;
  if (props.color) return props.color;
  if (props.palette) return props.theme.palette[props.palette].base;

  return props.theme.palette.primary.base;
};

export const activeButtonColor = (
  props: ButtonProps & { theme: Theme }
): string => {
  if (props.active) return transparentize(0.9, buttonColor(props));
  if (props.transparent) return "transparent";

  return `var(background)`;
};

export const buttonStyle = (props: ButtonProps & { theme: Theme }) => css`
  background-color: ${activeButtonColor(props)};
  border-radius: 6.25rem;
  margin: 0.6rem;
  padding: 1rem 2.4rem;
  cursor: pointer;
  font-size: ${props.theme.fontSize.base};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: ${props.theme.fonts.body};

  img {
    display: block;
    height: 3rem;
    margin: 0.2rem 2rem;
  }

  &::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background: red;
  }
`;
