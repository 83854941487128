import { z } from "zod";

import { ValidationError } from "./errors";

export type Schema<T> = z.ZodSchema<T, any, any>;

export type Unschema<S> = S extends Schema<infer T> ? T : never;

export type Guard<T> = (data: unknown, action: string) => Promise<T>;

export type Unguard<G> = G extends Guard<infer T> ? T : never;

export const wrapSchema =
  <T>(schema: Schema<T>): Guard<T> =>
  async (data, action) => {
    try {
      return await schema.parseAsync(data);
    } catch (error) {
      throw new ValidationError(error, "ERR-1400", action, data);
    }
  };


export const jsonParse = (
  input: string | undefined,
  action: string,
): unknown => {
  try {
    return input && JSON.parse(input);
  } catch (error) {
    throw new ValidationError(error, "ERR-1399", action, input);
  }
};

