export const useHost = () => ({
    close: () => {
      // eslint-disable-next-line no-alert
      alert("Closing browser component");
    },
    selectTab: (tab: number) => {
      // eslint-disable-next-line no-alert
      alert(`Selecting tab ${tab}`);
    },
    version: {
      major: 0,
      minor: 0,
      build: 0,
      revision: 0,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isEarlierThanVersion: ({ major, minor }: { major: number; minor: number }) =>
      false,
    open: (options: { url: string; title: string }) => {
      // eslint-disable-next-line no-alert
      alert(
        `Opening new browser component with url: ${options.url}, title: ${options.title}`
      );
    },
  });
  