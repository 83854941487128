import { useMutation } from "react-query";
import { MigrationSetImport } from "../shared";
import { useCallback } from "react";
import { requestHelper } from "../shared/utils";

const clearMigrationDataTypeUpload = async ({
  migrationSetImport,
  token,
}: {
  migrationSetImport: MigrationSetImport;
  token?: () => Promise<string>;
}): Promise<unknown> => {
  const endpoint = `/${migrationSetImport.migrationSetId}/${migrationSetImport.migrationImportId}`;
  await requestHelper(
    migrationSetImport.accountId,
    endpoint,
    `Failed to delete ${migrationSetImport.importType}.`,
    "DELETE",
    token,
  );
  return undefined;
};

export const useClearMigrationDataTypeUpload = () => {
  const { mutateAsync, isLoading } = useMutation(clearMigrationDataTypeUpload);

  const handleClearMigrationDataTypeClick = useCallback(async ({
    migrationSetImport,
    token,
  }: {
    migrationSetImport: MigrationSetImport;
    token?: () => Promise<string>;
  }) => {
    try {
      await mutateAsync({ migrationSetImport, token });
    } catch (error) {
      console.error("Error clearing import type: ", error);
    }
  }, [mutateAsync]);

  return {
    handleClearMigrationDataTypeClick,
    isLoading,
  };
};
