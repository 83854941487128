import { useEffect, useState } from "react";
import { Firm, UseLocalApiOptions } from "../types";
import { getLocalSmokeballAppContext, observeChange } from "../smokeballApp.utils.local";

export const useFirm = ({ observe }: UseLocalApiOptions) => {
  const [loading, setLoading] = useState(true);
  const [currentFirm, setCurrentFirm] = useState<Firm | undefined>();
  
  const shouldObserve = observe?.includes("firm");
  const localFirm = getLocalSmokeballAppContext()?.firm as Firm;

  useEffect(() => {
    if (shouldObserve) {
      setLoading(true);
      setCurrentFirm(localFirm);
      setLoading(false);

      // Observe local firm changes
      observeChange("firm", () => setCurrentFirm(localFirm));
    }
  }, [shouldObserve, localFirm]);

  return {
    get: async () => localFirm,
    current: currentFirm,
    loading,
  };
};
