import log from "./log";
import { UnauthorizedError } from "./errors";

export async function requestHelper(
  accountId: string,
  endpoint: string,
  errorMessage: string,
  methodType: string,
  token?: () => Promise<string>,
  body?: any, // Optional body for POST, PUT, PATCH requests
) {
  const tokenValue = token && (await token());
  const fullUrl = `/import/status/${accountId}${endpoint}`;

  // Use Headers object for dynamic header manipulation
  const headers = new Headers({
    authorization: `Bearer ${tokenValue}`,
  });
  // Add Content-Type header if body is provided
  if (body) {
    headers.append("Content-Type", "application/json");
  }
  // Construct the request options
  const fetchOptions: RequestInit = {
    headers,
    method: methodType,
  };
  // Add the body to fetch options if provided
  if (body) {
    fetchOptions.body = JSON.stringify(body);
    log("Body in request: ", JSON.stringify(body));
  }
  const response = await fetch(fullUrl, fetchOptions);


  if (response.status === 401) {
    log("Authorization error: 401 Unauthorized");
    throw new UnauthorizedError("Unauthorized access - 401 error.");
  }

  if (!response.ok) {
    log("Full URL with endpoint: ", fullUrl);
    log("Error message: ", errorMessage);

    throw new Error(`${errorMessage}`);
  }

  return response.json();
}
