const isNonProd = (appName: string | undefined) => ["app-dev", "app-staging"].includes(appName ?? 'app');

// Something wrong with tree shaking that means this code is considered dead code during build, and so substitution doesn't happen in Octopus.
// issue is that the env substitution is happening in Octopus deploy, which already means this has been converted
// as its value is what's stored in the .env file, which is "#{app_name}"
// Followed this thread for the solution: https://stackoverflow.com/questions/61318185/how-to-disable-dead-code-elimination-for-specific-blocks-or-files-in-webpack

const log = (message: string, data?: any) => {

  if (/*#__NOINLINE__*/isNonProd(process.env.REACT_APP_APP_NAME)) {
    if (data !== undefined) {
      console.log(message, data);
    } else {
      console.log(message);
    }
  }
};

export default log;