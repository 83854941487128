import React from "react";
import { QueryObserverResult } from "react-query";
import {
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card, CardIconWrapper } from "../card";
import {
  getInvoicesDescription,
  getTemplateKey,
  getInvoicesTooltip,
} from "../../functions/workflowFunctions";
import { CardDescription } from "../cardDescription";
import { InvoicesSVG, InvoicesUKSVG } from "../../icons";

interface CardInvoiceProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardInvoice: React.FC<CardInvoiceProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  const invoiceImport = data?.imports.find((i) => i.importType === "invoice");

  return (
    <Card
      image={
        region === "GB" ? (
          <CardIconWrapper>
            <InvoicesUKSVG />
          </CardIconWrapper>
        ) : (
          <CardIconWrapper>
            <InvoicesSVG />
          </CardIconWrapper>
        )
      }
      importType="invoice"
      templateKey={getTemplateKey("invoice", region)}
      fileName="invoice.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "invoice",
      )}
      headerText={ImportTypes.Invoices}
      description={
        <CardDescription
          {...getInvoicesDescription(region, invoiceImport)}
          displayWiderColumn={true} // Pass the displayWiderColumn flag prop as true for all invoices
        />
      }
      cardState={cardsState["invoice"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage={getInvoicesTooltip(region)}
    />
  );
};
