import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { COLOURS, MigrationSetWithImports, ImportTypes } from "../shared";
import { Info } from "lucide-react";
import { StandardButton } from "./buttons";
import { ImportTypeSelectionModal } from "../routes/basic/dashboard/importTypeSelection";
import { useUpdateMigration } from "../hooks/updateMigrationImportType";
import { Loader } from "./loader";
import { QueryObserverResult } from "react-query";
import { getRegionFromURL } from "../shared/utils/region";
import { PRODUCT_TIERS } from "../shared/utils/constants";
import log from "../shared/utils/log";

const StandardButtonNotes = styled(StandardButton)`
  width: 16rem;
  @media (max-width: 1436px) and (min-width: 1279px) {
    padding: 1rem 0.6rem;
    gap: 0rem;
  }
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  flex: 0 0 25%;
  max-width: 25%;
  background-color: ${COLOURS.notesContainerBackground};
  border: 1px solid ${COLOURS.notesContainerBorder};
  border-radius: 0.8rem;
  padding: 0.8rem;
  @media (max-width: 1421px) {
    flex: 0 0 40%;
    max-width: 40%;
  }
  @media (max-width: 1080px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
`;

const NotesHeading = styled.div`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const NotesRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 2rem 1rem 2rem;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  align-self: flex-start;
  vertical-align: top;
`;

const TextWrapper = styled.div`
  line-height: 1.8rem;
  margin-bottom: 1.5rem;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 400;
  color: ${COLOURS.notesTextWrapperColor};
  vertical-align: top;
`;

const Link = styled.a`
  color: ${COLOURS.notesLinkColor};
  text-decoration: underline;
  cursor: pointer;
`;

const AdditionalItemsRow = styled.div`
  display: flex;
  margin-top: 5rem;
  margin-left: 2rem;
  gap: 0.2rem;
  align-items: flex-end;

  @media (max-width: 2132px) {
    align-items: flex-start;

    @media (min-width: 1377px) and (max-width: 1421px) {
      align-items: flex-end;
    }
  }
`;

export const Notes: React.FC<{
  isJumpStart: boolean;
  productId: string | null;
  existingImportTypes: string[];
  migrationSetId: number;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
}> = ({
  isJumpStart,
  productId,
  existingImportTypes,
  migrationSetId,
  refetchMigrationSet,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddItems = () => {
    log("Existing import types: ", existingImportTypes);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle update of new import types from the modal
  const { handleUpdateMigrationClick, isLoading } = useUpdateMigration();

  const handleConfirmUpdate = async (updatedImportTypes: string[]) => {
    try {
      // Merge existing and new import types
      const mergedImportTypes = [...existingImportTypes, ...updatedImportTypes];

      // Deduplicate the merged array
      const uniqueImportTypes = Array.from(new Set(mergedImportTypes));
      log("Updated unique import types:", uniqueImportTypes);

      // Update the migration set with the deduplicated list
      const updatedData = await handleUpdateMigrationClick(
        migrationSetId,
        uniqueImportTypes,
      );

      // Refetch to get the updated migration set
      await refetchMigrationSet();
      log("Updated migration set data:", updatedData);
    } catch (error) {
      console.error("Failed to update migration:", error);
    }
  };

  // Dynamically calculate available import types based on region and tier
  const availableImportTypes = useMemo(() => {
    const region = getRegionFromURL();
    const isGrowOrProsper =
      productId === PRODUCT_TIERS.GROW || productId === PRODUCT_TIERS.PROSPER;

    let types: string[] = [
      ImportTypes.Staff,
      ImportTypes.Contacts,
      ImportTypes.Matters,
      ImportTypes.MatterTypes,
      ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
      ImportTypes.OpeningBalances,
      ImportTypes.ActivityTypes,
      ImportTypes.UnbilledTimeAndFees,
      ImportTypes.UnbilledDisbursements,
      ImportTypes.Invoices,
    ];

    if (
      productId === PRODUCT_TIERS.BOOST ||
      productId === PRODUCT_TIERS.GROW ||
      productId === PRODUCT_TIERS.PROSPER
    ) {
      types.push(ImportTypes.Memos, ImportTypes.Tasks);
    }

    if (
      (productId === PRODUCT_TIERS.GROW ||
        productId === PRODUCT_TIERS.PROSPER) &&
      region !== "US"
    ) {
      types.push(
        ImportTypes.SafeCustodyPackets,
        ImportTypes.SafeCustodyDocuments,
      );
    }

    return types;
  }, [productId]);

  // Determine if all available types are already selected
  const allSelected = useMemo(
    () =>
      availableImportTypes.every((type) => existingImportTypes.includes(type)),
    [availableImportTypes, existingImportTypes],
  );

  const NoteItem = ({ text, link }: { text: string; link?: string }) => (
    <NotesRow>
      <IconWrapper>
        <Info
          size="20px"
          style={{
            color: COLOURS.notesIconWrapperInfoColor,
            verticalAlign: "top",
          }}
        />
      </IconWrapper>
      <TextWrapper>
        {link ? (
          <>
            <Link href={link} target="_blank">
              {"Click here"}
            </Link>
            {text}
          </>
        ) : (
          text
        )}
      </TextWrapper>
    </NotesRow>
  );

  return (
    <NotesContainer>
      <NotesRow>
        <NotesHeading>Notes:</NotesHeading>
      </NotesRow>

      <NoteItem text="Uploaded files must be in CSV, XLSX, or XLS format." />
      <NoteItem text="Duplicate checks are NOT performed against existing data created pre-import." />
      <NoteItem
        text=" for more information on preparing your source data."
        link={process.env.REACT_APP_MIG_JUMPSTART_DOCUMENT_PREPARATION_URL}
      />

      {/* Conditionally show "Add Items" button */}
      {isJumpStart && !allSelected && (
        <AdditionalItemsRow>
          <TextWrapper>Include additional items in this import:</TextWrapper>
          <StandardButtonNotes onClick={handleAddItems}>
            Add Items
          </StandardButtonNotes>
        </AdditionalItemsRow>
      )}
      {isModalOpen && (
        <ImportTypeSelectionModal
          closeModal={closeModal}
          onConfirm={handleConfirmUpdate}
          productId={productId || ""}
          titleText="Add Items"
          subtitleText="Select items to add to your import"
          existingImportTypes={existingImportTypes}
        />
      )}
      {isLoading && <Loader />}
    </NotesContainer>
  );
};
