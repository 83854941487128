import { UseSDKApiOptions } from "../types";
import { SDKError } from "../utils/errors";

export const useAuth = ({ client, isInit }: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  return {
    token: async () => {
      try {
        return await client.auth.token();
      } catch (error) {
        throw new SDKError(error, { action: "auth.token" });
      }
    },
    idToken: async () => {
      try {
        return await client.auth.idToken();
      } catch (error) {
        throw new SDKError(error, { action: "auth.idToken" });
      }
    },
  };
};
