import React from "react";
import { Package } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";

interface CardSafeCustodyPacketProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardSafeCustodyPacket: React.FC<CardSafeCustodyPacketProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
}) => {
  return (
    <Card
      image={
        <Package
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="safeCustodyPacket"
      templateKey="safeCustodyPacketImport"
      fileName="safeCustodyPacket.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "safeCustodyPacket")}
      headerText={ImportTypes.SafeCustodyPackets}
      description="Safe Custody Packets must be associated to a Contact. Therefore, Contacts must be uploaded and valid before Packets can be added."
      cardState={cardsState["safeCustodyPacket"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Contacts must be uploaded and valid before Safe Custody Packets can be added."
    />
  );
};
