export type SDKRequestDetails = {
    action: string;
    input?: object;
  };
  
  export class SDKError extends Error {
    public action: string;
  
    public input?: object;
  
    constructor(error: unknown, { action, input }: SDKRequestDetails) {
      let code;
      let message;
      if (error instanceof Error) {
        code = error.name;
        message = error.message;
      } else {
        code = `${error}`;
        message = `${error}`;
      }
  
      super(`${code} in ${action}: ${message}`);
  
      if (error instanceof Error) this.stack = error.stack;
  
      this.action = action;
      this.input = input;
    }
  }
  