import { z } from "zod";

import { jsonParse, wrapSchema } from "./validation";
export const cognitoTokenSchema = z
  .object({
    sub: z.string(),
    device_key: z.string().optional(),
    token_use: z.enum(["access", "refresh", "id"]),
    auth_time: z.number(),
    iss: z.string(),
    exp: z.number(),
    iat: z.number(),
    username: z.string().optional(),
    scope: z.string().optional(),
    jti: z.string().optional(),
    client_id: z.string().optional(),
    aud: z.string().optional(),
    "cognito:username": z.string().optional(),
  })
  .passthrough();

export const guardCognitoToken = wrapSchema(cognitoTokenSchema);

export const extractJWT = (token: string) => {
  const parts = token.replace(/^Bearer /, "").split(".");
  const decode = (part: string) => {
    if (typeof Buffer !== "undefined") {
      return Buffer.from(part, "base64").toString("utf8");
    }
    return window.atob(part);
  };
  return jsonParse(decode(parts[1]), "extract-jwt");
};

export const extractCognitoToken = (token: string): any => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding token:", error);
    return {};
  }
};
