import styled from "@emotion/styled";

import { buttonColor, ButtonProps } from "./utils";

export const ButtonColumn = styled.div`
  display: flex;

  flex-flow: column;
  align-items: center;
  justify-content: center;

  margin: 1rem 0;
  width: 100%;
`;

export const ButtonRow = styled.div<{ align?: string }>`
  display: flex;

  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin: 1rem 0;
`;

export const Hideable = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "block")};

  .handle {
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonGroup = styled.div<ButtonProps>`
  display: flex;
  width: fit-content;
  border: 1px solid ${buttonColor};
  border-radius: 6.25rem;
  margin: 1rem;
  padding: 0.2rem 0.4rem;

  button {
    border-radius: 0rem;
    margin: 0rem;
    padding: 0.8rem 1.6rem;
  }

  button:not(:first-of-type) {
    border-left: 1px solid ${(props) => props.theme.palette.disabled.base};
  }

  button:first-of-type {
    border-radius: 6.25rem 0rem 0reem 6.25rem;
  }

  button:last-of-type {
    border-radius: 0rem 6.25rem 6.25rem 0rem;
  }
`;
