import { useQuery } from "react-query";
import {
  AccountDetails,
  MigrationSetResponse,
  MigrationSetWithImports,
  useSmokeballApp,
} from "../shared";
import log from "../shared/utils/log";
import { requestHelper, UnauthorizedError } from "../shared/utils";
import { useState } from "react";

const fallbackResponse: MigrationSetResponse = {
  accountDetails: {
    accountId: "",
    firmName: "",
    owner: "",
    userIds: [],
    addressCountry: null,
    addressState: null,
    countryCode: "",
    productId: "",
    isInternalAccount: false,
  },
  migrationSetWithImports: [],
};

const fetchExistingMigrations = async ({
  accountId,
  token,
}: {
  accountId: string;
  token?: () => Promise<string>;
}): Promise<MigrationSetResponse> => {
  const endpoint = ``;
  const data = await requestHelper(
    accountId,
    endpoint,
    "Failed to list imports.",
    "GET",
    token,
  );
  log("JSON data of response to fetch existing import: ", data);

  // Ensure a consistent structure regardless of API behaviour
  return {
    accountDetails: data.accountDetails || fallbackResponse.accountDetails,
    migrationSetWithImports: data.migrationSets || [], // Map API migrationSets to migrationSetWithImports
  };
};

export const useExistingMigrations = () => {
  const { auth, context } = useSmokeballApp();
  const accountId = context?.firmId || "";
  const token = auth?.token;

  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const { data, isLoading } = useQuery<MigrationSetResponse>({
    queryKey: ["migrations", accountId],
    queryFn: async () => {
      try {
        const result = await fetchExistingMigrations({ accountId, token });

        setIsUnauthorized(false); // Reset unauthorized state on success
        return result;
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          // Stop retrying on UnauthorizedError
          setIsUnauthorized(true);
          return fallbackResponse; // Use fallback response for 401 errors
        }
        throw error;
      }
    },
    refetchInterval: 10000,
    enabled: !!accountId,
    refetchOnWindowFocus: false,
  });

  // Extract account details and migrations for direct use
  const accountDetails: AccountDetails | null = data?.accountDetails || null;
  const migrationSetWithImports: MigrationSetWithImports[] = data?.migrationSetWithImports || [];

  return {
    accountDetails,
    migrationSetWithImports,
    isLoading,
    isUnauthorized,
  };
};
