export const COLOURS = {
  // BUTTONS
  // standard button
  standardButtonColor: "#000000",
  standardButtonBackground: "#fff",
  standardButtonBorder: "#DDE0E9",
  // primary button
  primaryButtonColor: "#fff",
  primaryButtonBackground: "#1A56DB",
  primaryButtonBorder: "#1A56DB",
  // replace button
  replaceButtonColor: "#000",
  replaceButtonBackground: "#E8EEFB",
  replaceButtonBorder: "#A3BBF1",
  // cancel button
  cancelButtonColor: "#fff",
  cancelButtonBackground: "#E60C19",
  cancelButtonBorder: "#E60C19",
  // open and view import buttons
  openViewButtonColor: "#000",
  openButtonBackground: "#E8EEFB",
  openButtonBorder: "#A3BBF1",
  viewButtonBackground: '#fff',
  viewButtonBorder: "#DDE0E9",
  // button link and direct link
  buttonLinkColor:  "#1A56DB",

  // CARD
  cardHeading2Color: "#101828",
  cardParagraphColor: "#546690",
  cardWrapperBackground: "#fff",
  cardWrapperBorder: "#E8EBF0",

  // ERROR BAR
  errorBarColor: "#000",
  errorBarBackground:"#F9EE9A",
  
  // ICONS
  // cancel and clear modal icons
  cancelAndClearIconBackground: "#FACED1",
  cancelAndClearIconColor: "#E60C19",
  // card icon
  cardIconWrapperBackground: "#D1DDF8",
  cardIconColor: "#1A56DB",
  // matter state selection icon
  matterStateSelectionIconColor: "#E6CB00",
  matterStateSelectionIconBackground: "#FBF3BB",
  // new migration icon
  newMigrationIconWrapperBackground: "#D1E9FF",
  newMigrationIconColor: "#1A56DB",
  // pill icon
  pillIconColor: "#384460",  
  // styled icon for button
  styledIconColor: "#fff",
  // submit modal icon
  submitModalIconColor: "#1A56DB",
  // summary modal icon
  summaryModalIconColor: "#1A56DB",
  // title icon
  titleIconBackground: "#fff",
  titleIconBorder: "#E5E7EB",

  // IMAGES
  styledImageDefaultBackground: "#D1E9FF",
  styledImageDefaultBorder: "#EFF8FF",
  
  // LOADER  
  loaderColor: "#1570EF",
  loaderOverlayBackground: "#fff",

  // STANDARD MODAL
  modalContainerBackground: '#fff',
  modalHeadingInfoRowColor: "#101828",
  modalParagraphColor: '#546690',
  modalDefaultBackgroundColor: "#D1E9FF",
  modalRadioButtonInputBackground: '#fff',
  modalRadioButtonInputBorder: "#DDE0E9",
  modalRadioCheckedButtonInputBackground: '#EBF9FC',
  modalRadioCheckedButtonInputBorder: '#2CB3CB',

  // DATA IMPORT MODAL
  importModalContainerBackground: '#fff',
  importModalHeadingColor: "#1C2230",
  importModalImportTypeHeadingColor: "#111928",
  importModalImportTypeParagraphColor: '#6B7280',
  importModalCloseButton: '#9CA3AF',  
  importModalDivider: '#E5E7EB',
  importModalImportTypeBorder: '#D0D5DD',
  importModalImportTypeSelectedBackground:'#F3FBFD',
  importModalDependencyCheckIconBackground: '#3CBA86',

  // NOTES
  notesContainerBackground: '#F3F5F7',
  notesContainerBorder: "#DDE0E9",
  notesTextWrapperColor: '#000000',
  notesIconWrapperInfoColor: '#546690',
  notesLinkColor: "#1A56DB",

  // PAGE
  pageContentWrapperBackround: "#FAFBFC",
  pageBoxBackground: '#fff',
  pageBoxHeading1Color: "#051639",
  pageBoxParagraphColor: '#7080A2',

  // PILLS
  pillTextColor: "#384460",
  successBackgroundColor: '#93F2CA',
  readyBackgroundColor: '#D6F3F8',
  disabledBackgroundColor: '#E8EBF0',
  warningBackgroundColor: "#F9EE9A",
  errorBackgroundColor: "#FACED1",
  
  // TABLE
  tableHeaderColor: "#667085",
  tableBorder: "#DDE0E9",
  tableCellTextColor: "#101828",
  tableRowBackgroundGrey: "#F7F8FA",
  tableRowBackgroundWhite: "#fff",

  // TEXT
  standardBlackText: '#000',

  // TITLE
  titleWrapperBackground: '#fff',
  titleBorder: "#E5E7EB",
  
  // TOOL TIP
  toolTipContentColor: "#344054",
  toolTipContentBackground: '#fff',
};
