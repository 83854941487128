import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import {
  CancelMigrationButton,
  Loader,
  Notes,
  Page,
  Row,
  SubmitButton,
} from "../../../components";
import { COLOURS, ImportTypes, useSmokeballApp } from "../../../shared";
import { CancelMigrationModal } from "./cancelMigrationModal";
import { SubmitMigrationModal } from "./submitMigrationModal";
import { useMigration } from "../../../hooks";
import { useRegion } from "../../../shared/utils/region";
import { useCardState } from "../../../hooks/useCardState";
import {
  CardActivity,
  CardContact,
  CardInvoice,
  CardInvoiceItem,
  CardMatter,
  CardMatterOpeningBalance,
  CardMatterRelationship,
  CardMatterType,
  CardMemo,
  CardStaff,
  CardTask,
  CardUnbilledExpense,
} from "../../../components/cards";
import { CardUnbilledTimeAndFee } from "../../../components/cards/unbilledTimeAndFee";
import { CardSafeCustodyPacket } from "../../../components/cards/safeCustodyPacket";
import { CardSafeCustodyDocument } from "../../../components/cards/safeCustodyDocument";
import {
  getAllowedImportTypes,
  isOldFormatImportType,
} from "../../../shared/utils/importTypeFormat";
import ErrorScreen from "../dashboard/authErrorScreen";
import log from "../../../shared/utils/log";

const WorkflowRow = styled(Row)`
  padding: 4rem 8rem 2rem 8rem;
  display: flex;
  align-items: flex-start;
  
  /* Ensure Notes and Cards distribute space proportionally */
  > div {
    flex: 1; /* Allow children to share available space */
  }
`;

const StyledLink = styled(Link)`
  color: ${COLOURS.buttonLinkColor};
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 600;
  margin: 1rem;
  display: flex;
  align-items: center;
  svg {
    // Targeting SVG children directly
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
`;

const AdjustableCardContainer = styled.div`
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-right: -0.8rem;

  /* Cards should align at the top of their container */
  align-items: flex-start;

  @media (max-width: 1421px) {
    width: 66%;
  }
`;

export interface CardState {
  hasUploaded: Boolean;
  hasErrors: boolean;
  hasWarnings: boolean;
  uploadErrorCount: number;
  uploadWarningCount: number;
  uploadTotalRecordCount: number;
  isSuccess: boolean;
  isReady: boolean;
  isDisabled: boolean;
  needsRevalidation: boolean;
}

const Hyperlink = () => {
  return (
    <StyledLink to="/embedded/jumpstart">
      <MoveLeft />
      Back to all imports
    </StyledLink>
  );
};

export const Workflow = () => {
  const { auth, context, staff } = useSmokeballApp();
  const { id } = useParams();
  const currentMigrationSetId = id !== undefined ? parseInt(id, 10) : NaN;
  const existingStaffCount = staff?.current?.length || 0;

  const {
    data,
    isLoading,
    isUnauthorized,
    refetch: refetchMigrationSet,
    blockingUserName,
    disableAccessToSecondaryUser,
    disableAccessAsPrimaryUserCancelled,
    disableAccessAsPrimaryUserSubmitted,
  } = useMigration({
    migrationSetId: currentMigrationSetId,
    accountId: context?.firmId || "",
    token: auth?.token,
  });
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [errorBar, setErrorBar] = useState({ visible: false, message: "" });
  const disableButtonAccess =
    disableAccessToSecondaryUser ||
    disableAccessAsPrimaryUserCancelled ||
    disableAccessAsPrimaryUserSubmitted;

  const cardsState = useCardState(data, existingStaffCount);

  const region = useRegion(data?.accountBillingCountryCode);

  const sourceSystem = data?.sourceSystem;

  const isJumpStart = sourceSystem === "jumpstart";

  const productId = data?.productId;

  // Safely determine import types based on the old or new format
  // or on existing import types previously selected
  const existingImportTypes = JSON.parse(data?.importTypes || "[]");
  log("Existing Import Types:", existingImportTypes);

  // Safely determine import types based on the old or new format
  let importTypes: string[] = [];

  if (isOldFormatImportType(data?.importTypes || "")) {
    importTypes = getAllowedImportTypes(region, productId || "");
  } else {
    try {
      importTypes = existingImportTypes; // Use the parsed array directly
    } catch (error) {
      console.error("Error parsing importTypes:", error);
      importTypes = []; // Fallback to an empty array if parsing fails
    }
  }

  // Logic to check which cards should be displayed
  const shouldShowCard = (importType: any) => importTypes.includes(importType);

  // Display error bar if any import is in progress
  useEffect(() => {
    if (disableAccessToSecondaryUser) {
      setErrorBar({
        visible: true,
        message: `${blockingUserName} is actively working on this import. Simultaneous data upload and editing are not supported. Access will be enabled once ${blockingUserName} has finished.`,
      });
    } else if (
      disableAccessAsPrimaryUserCancelled ||
      disableAccessAsPrimaryUserSubmitted
    ) {
      const actionTaken = disableAccessAsPrimaryUserCancelled
        ? "cancelled"
        : "submitted";
      const regionalSpelling =
        region === "US"
          ? actionTaken === "cancelled"
            ? "canceled"
            : "submitted"
          : actionTaken;
      setErrorBar({
        visible: true,
        message: `This import has been ${regionalSpelling} by another user, no further action can be performed. Please close this import.`,
      });
    } else {
      setErrorBar({ visible: false, message: "" });
    }
  }, [
    disableAccessToSecondaryUser,
    disableAccessAsPrimaryUserCancelled,
    disableAccessAsPrimaryUserSubmitted,
    blockingUserName,
    region,
  ]);

  const openCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const openSubmitModal = () => {
    const hasErrors = data?.imports.some(
      (importData) => importData.uploadErrorCount > 0,
    );

    const needsRevalidation = data?.imports.some(
      (importData) => importData.uploadErrorCount === -1,
    );

    const hasNoDataUploaded =
      data?.imports?.length === 0 ||
      !data?.imports.some((importData) => importData.hasUploaded);

    if (hasErrors || needsRevalidation) {
      setErrorBar({
        visible: true,
        message:
          "Please resolve all errors or clear data types not needed. If importing Matters, make sure you have configured Matter Types.",
      });
    } else if (hasNoDataUploaded) {
      setErrorBar({ visible: true, message: "Please upload some data." });
    } else {
      setIsSubmitModalOpen(true);
      setErrorBar({ visible: false, message: "" });
    }
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const closeSubmitModal = () => {
    setIsSubmitModalOpen(false);
  };

  if (isUnauthorized) {
    return <ErrorScreen />;
  }

  if (isLoading || !data || !region || region === "Not set") {
    return <Loader />;
  }

  return (
    <>
      {isLoading && <Loader />}
      <Page
        titleProps={{
          topLeftContent: (
            <>
              <Hyperlink /> {/* Left content */}
            </>
          ),
          bottomRightContent: (
            <>
              <CancelMigrationButton
                onClick={openCancelModal}
                disableButtonAccess={disableButtonAccess}
              >
                Cancel Import
              </CancelMigrationButton>
              <SubmitButton
                onClick={openSubmitModal}
                isSubmitButtonDisabled={false}
                disableButtonAccess={disableButtonAccess}
              >
                Submit Import
              </SubmitButton>
            </>
          ),
        }}
        errorBar={errorBar}
      >
        <>
          <WorkflowRow>
            <div style={{ display: "flex" }}>
              {/* This function needs to change to not be refetchmigration set but to do all the things we need it to from thenotes if the button is pressed */}
              <Notes
                isJumpStart={isJumpStart}
                productId={productId || ""}
                existingImportTypes={importTypes}
                migrationSetId={currentMigrationSetId}
                refetchMigrationSet={refetchMigrationSet}
              />
              <AdjustableCardContainer>
                {shouldShowCard(ImportTypes.Staff) && (
                  <CardStaff
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                  />
                )}
                {shouldShowCard(ImportTypes.Contacts) && (
                  <CardContact
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.Matters) && (
                  <CardMatter
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.MatterTypes) && (
                  <CardMatterType
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.MatterRelationships) && (
                  <CardMatterRelationship
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.OpeningBalances) && (
                  <CardMatterOpeningBalance
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.ActivityTypes) && (
                  <CardActivity
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.UnbilledTimeAndFees) && (
                  <CardUnbilledTimeAndFee
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.UnbilledDisbursements) && (
                  <CardUnbilledExpense
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.Invoices) && (
                  <CardInvoice
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.InvoiceItems) &&
                  sourceSystem !== "jumpstart" && (
                    <CardInvoiceItem
                      currentMigrationSetId={currentMigrationSetId}
                      data={data}
                      cardsState={cardsState}
                      disableButtonAccess={disableButtonAccess}
                      refetchMigrationSet={refetchMigrationSet}
                      region={region}
                    />
                  )}
                {shouldShowCard("Memos") && (
                  <CardMemo
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.Tasks) && (
                  <CardTask
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.SafeCustodyPackets) && (
                  <CardSafeCustodyPacket
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
                {shouldShowCard(ImportTypes.SafeCustodyDocuments) && (
                  <CardSafeCustodyDocument
                    currentMigrationSetId={currentMigrationSetId}
                    data={data}
                    cardsState={cardsState}
                    disableButtonAccess={disableButtonAccess}
                    refetchMigrationSet={refetchMigrationSet}
                    region={region}
                  />
                )}
              </AdjustableCardContainer>
            </div>
          </WorkflowRow>
        </>
      </Page>
      {isCancelModalOpen && (
        <CancelMigrationModal closeModal={closeCancelModal} region={region} />
      )}
      {isSubmitModalOpen && (
        <SubmitMigrationModal closeModal={closeSubmitModal} />
      )}
      {isLoading && <Loader />}
    </>
  );
};
