import styled from "@emotion/styled";
import { COLOURS } from "../shared";
import React from "react";

interface BasePillProps {
  borderRadius?: number;
  fontSize?: number;
  padding?: string;
}
const BasePill = styled.div<BasePillProps>`
  display: inline-flex;
  padding: ${({ padding }) => padding || "0.2rem 0.8rem"};
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize}px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  width: auto;
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

export type Scheme = "WARNING" | "ERROR" | "DISABLED" | "SUCCESS" | "READY";

const colours: Record<Scheme, { color: string; backgroundColor: string }> = {
  WARNING: {
    color: `${COLOURS.pillTextColor}`,
    backgroundColor: `${COLOURS.warningBackgroundColor}`,
  },
  ERROR: {
    color: `${COLOURS.pillTextColor}`,
    backgroundColor: `${COLOURS.errorBackgroundColor}`,
  },
  DISABLED: {
    color: `${COLOURS.pillTextColor}`,
    backgroundColor: `${COLOURS.disabledBackgroundColor}`,
  },
  SUCCESS: {
    color: `${COLOURS.pillTextColor}`,
    backgroundColor: `${COLOURS.successBackgroundColor}`,
  },
  READY: {
    color: `${COLOURS.pillTextColor}`,
    backgroundColor: `${COLOURS.readyBackgroundColor}`,
  },
};

type PillProps = {
  text: React.ReactNode;
  colorScheme: Scheme;
  icon?: React.ReactNode;
  lineHeight?: number;
  width?: number;
  borderRadius?: number;
  fontSize?: number;
  padding?: string;
};

export const Pill: React.FC<PillProps> = ({
  text,
  colorScheme,
  icon,
  lineHeight,
  width,
  padding,
  borderRadius,
  fontSize,
}) => {
  // Define a placeholder for maintaining height without adding width for when there is no icon
  const heightPlaceholder = (
    <div
      style={{
        height: "1.4rem",
        width: 0,
        marginRight: "0.3rem",
        display: "inline-block",
      }}
    ></div>
  );

  return (
    <BasePill
      style={{
        ...colours[colorScheme],
        lineHeight: `${lineHeight}`,
        width: `${width}`,
        padding: `${padding}`,
        borderRadius: borderRadius,
        fontSize: fontSize,
      }}
    >
      {/* Render icon, which may be with actual icon or with height placeholder */}
      {icon || heightPlaceholder}
      <span>{text}</span>
    </BasePill>
  );
};
