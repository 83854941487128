import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Page, StyledIconButton, Tooltip } from "../../../components";
import { SummaryInfoModal } from "./summaryInfo";
import { NewMigration } from "./newMigration";
import { ExistingMigration } from "./existingMigration";
import { useExistingMigrations, useNewMigration } from "../../../hooks";
import { Plus } from "lucide-react";
import { useAuthContext } from "../authContext";
import ErrorScreen from "./authErrorScreen";
import { ImportTypeSelectionModal } from "./importTypeSelection";

const DisabledButtonWrapper = styled.div`
  float: right;
`;

const Dashboard = () => {
  const [showImportModal, setShowImportModal] = useState(false);
  const {
    handleNewMigrationClick,
    isLoading: isCreatingMigration,
    isUnauthorized: isNewMigrationUnauthorized,
  } = useNewMigration();
  const [migrationSetId, setMigrationSetId] = useState<number | null>(null);
  const {
    accountDetails,
    migrationSetWithImports,
    isLoading: isDataLoading,
    isUnauthorized: isExistingMigrationUnauthorized,
  } = useExistingMigrations();
  const { isAuthInitialized } = useAuthContext();
  const navigate = useNavigate();

  // Determine if any migration is not complete
  const isAnyMigrationInProgress = useMemo(() => {
    return migrationSetWithImports
      ? migrationSetWithImports.some(
          (migration) => migration.status !== "Complete",
        )
      : false;
  }, [migrationSetWithImports]);

  const productId = accountDetails?.productId;

  if (
    isDataLoading ||
    !isAuthInitialized ||
    isAnyMigrationInProgress === undefined ||
    isCreatingMigration
  ) {
    return <Loader transparent={false} />;
  }

  if (
    isExistingMigrationUnauthorized ||
    isNewMigrationUnauthorized ||
    (isAuthInitialized && !productId)
  ) {
    return <ErrorScreen />;
  }

  // Function to handle the opening of the modal
  const handleNewImportClick = () => {
    setShowImportModal(true); // Open the modal when clicked
  };

  // Handle confirmation from the modal
  const handleConfirm = async (importTypes: string[]) => {
    try {
      const migrationSetId = await handleNewMigrationClick(importTypes); // Call the hook to create migration

      // Ensure navigation happens after migration is created
      if (migrationSetId) {
        navigate(`/embedded/jumpstart/workflow/${migrationSetId}`); // Navigate after success
      } else {
        console.error("Migration creation failed");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <>
      <Page
        titleProps={{
          bottomRightContent: migrationSetWithImports?.length ? (
            <DisabledButtonWrapper>
              <Tooltip
                enabled={isAnyMigrationInProgress}
                message="An import is already in progress."
              >
                <StyledIconButton
                  disabled={isAnyMigrationInProgress}
                  text="New import"
                  icon={<Plus size={20} className="icon" />}
                  onClick={async () => {
                    if (!isAnyMigrationInProgress) {
                      handleNewImportClick();
                    }
                  }}
                />
              </Tooltip>
            </DisabledButtonWrapper>
          ) : undefined,
        }}
      >
        {isDataLoading ? ( // Show loader while loading
          <Loader transparent={false} />
        ) : migrationSetWithImports?.length ? ( // Show existing imports only when they exist
          <ExistingMigration
            data={migrationSetWithImports}
            selectMigration={async (id, status) => {
              const selectedMigration = migrationSetWithImports.find(
                (m) => m.migrationSetId === id,
              );

              if (!selectedMigration) {
                console.error(`Migration with ID ${id} not found.`);
                return;
              }

              if (
                status === "Submitted" ||
                status === "Loading" ||
                status === "Complete"
              ) {
                setMigrationSetId(id);
              } else {
                navigate(`workflow/${id}`, {
                  state: { migrationData: selectedMigration },
                });
              }
            }}
          />
        ) : (
          <NewMigration handleConfirm={handleConfirm} productId={productId}/>
        )}
        {!!migrationSetId && (
          <SummaryInfoModal
            migrationSetId={migrationSetId}
            onClose={() => setMigrationSetId(null)}
          />
        )}
      </Page>
      {showImportModal && (
        <ImportTypeSelectionModal
          closeModal={() => setShowImportModal(false)}
          onConfirm={handleConfirm}
          productId={productId}
          titleText="New Import"
          subtitleText="Select items to include in your import"
          existingImportTypes={[]}
        />
      )}
    </>
  );
};

export { Dashboard };
