import { Change, LocalSmokeballAppContext } from "./types";

export const LOCAL_SMOKEBALL_APP_CONTEXT_KEY = "local-smokeball-app-context";
const CHANGE_OBSERVER_CHANNEL_NAME = "change-observer-channel";

/**
 * To set local context, use the integration.smokeballApp: localContextGet roll.
 * It needs a matter id (the matter needs to exist in your local DB) and will
 * return a script to set the local context based on the matter. You can then copy
 * the script (without the wrapping double quote) and run it in the browser console.
 */
export const getLocalSmokeballAppContext = () => {
  const context = localStorage.getItem(LOCAL_SMOKEBALL_APP_CONTEXT_KEY);

  return context
    ? (JSON.parse(context) as LocalSmokeballAppContext)
    : undefined;
};

export const setLocalSmokeballAppContext = (
  context: LocalSmokeballAppContext,
) => {
  localStorage.setItem(
    LOCAL_SMOKEBALL_APP_CONTEXT_KEY,
    JSON.stringify(context),
  );
};

/**
 * We use BroadcastChannel in local mode to simulate context change.
 * To reflect a change without reloading the page, change the context value
 * in localStorage and post a message corresponding to the change. For example,
 * new BroadcastChannel("change-observer-channel").postMessage("matters");
 * will trigger rerender for hooks listening to matter changes.
 */
export const observeChange = (change: Change, callback: () => void) => {
  const channel = new BroadcastChannel(CHANGE_OBSERVER_CHANNEL_NAME);
  channel.addEventListener("message", (event) => {
    if (event.data === change) {
      callback();
    }
  });
};

export const publishChange = (change: Change) => {
  new BroadcastChannel(CHANGE_OBSERVER_CHANNEL_NAME).postMessage(change);
};
