import React from "react";
import styled from "@emotion/styled";
import { COLOURS } from "../shared";
import { Info } from "lucide-react";

const ErrorBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOURS.errorBarBackground};
  color: ${COLOURS.errorBarColor};
  height: 4.8rem;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 400;
  padding: 1.5rem;
`;

export const ErrorBar: React.FC<{ text: string }> = ({ text }) => {
  return (
    <ErrorBarContainer>
      <Info
            size={20}
            strokeWidth={2}
            style={{
              marginRight: "0.5rem",
            }}
          />
      {text}
    </ErrorBarContainer>
  );
};
