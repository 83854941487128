import { useEffect, useState } from "react";

// import { resolvePromiseWithDelay } from "@smart/itops-utils-basic";

import { getLocalSmokeballAppContext, observeChange } from "../smokeballApp.utils.local";
import { Staff, UseLocalApiOptions } from "../types";

export const useStaff = ({ observe }: UseLocalApiOptions) => {
  const [loading, setLoading] = useState(true);
  const [currentStaff, setCurrentStaff] = useState<Staff[]>([]);
  const shouldObserve = observe?.includes("staff");
  const localStaff = getLocalSmokeballAppContext()?.staff as Staff[];

  useEffect(() => {
    if (shouldObserve) {
      setLoading(true);
      setCurrentStaff(localStaff);
      setLoading(false);

      observeChange("layouts", () => setCurrentStaff(localStaff));
    }
  }, [shouldObserve, localStaff]);

  return {
    // all: () => resolvePromiseWithDelay(localStaff),
    all: async () => localStaff,
    current: currentStaff,
    loading,
  };
};
