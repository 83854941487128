import { useEffect, useState } from "react";
import { getDefaultCardState } from "../functions/workflowFunctions";
import { CardState } from "../routes/basic/workflow";

interface CardsState {
  [key: string]: CardState;
}

export const useCardState = (data: any, existingStaffCount: number) => {
  const [cardsState, setCardsState] = useState<Record<string, any>>({});

  const initialCardsState: CardsState = {
    staff: getDefaultCardState(),
    contact: getDefaultCardState(),
    matter: getDefaultCardState(false, true),
    matterType: getDefaultCardState(false, true),
    matterOpeningBalance: getDefaultCardState(false, true),
    matterRelationship: getDefaultCardState(false, true),
    activity: getDefaultCardState(),
    unbilledTimeAndFee: getDefaultCardState(false, true),
    unbilledExpense: getDefaultCardState(false, true),
    invoice: getDefaultCardState(false, true),
    invoiceItem: getDefaultCardState(false, false),
    memo: getDefaultCardState(false, true),
    task: getDefaultCardState(),
    safeCustodyPacket: getDefaultCardState(false, true),
    safeCustodyDocument: getDefaultCardState(false, true),
  };

  useEffect(() => {
    const imports = data?.imports || [];
    let needsRevalidation = false;

    const updatedCardsState = imports.reduce(
      (
        acc: {
          [x: string]: {
            hasUploaded: any;
            hasWarnings: boolean;
            hasErrors: boolean;
            uploadWarningCount: any;
            uploadErrorCount: any;
            uploadTotalRecordCount: any;
            isSuccess: boolean;
            isReady: boolean;
            isDisabled: boolean;
            needsRevalidation: boolean;
          };
        },
        importData: {
          importType: any;
          hasUploaded: any;
          uploadErrorCount: any;
          uploadWarningCount: any;
          uploadTotalRecordCount: any;
        },
      ) => {
        const {
          importType,
          hasUploaded,
          uploadErrorCount,
          uploadWarningCount,
          uploadTotalRecordCount,
        } = importData;

        const hasWarnings = uploadWarningCount > 0;
        const hasErrors = uploadErrorCount > 0;
        needsRevalidation = uploadErrorCount === -1;
        const isSuccess = !hasErrors && !!hasUploaded && !needsRevalidation;

        // Update card states based on import data
        acc[importType] = {
          ...getDefaultCardState(),
          hasUploaded,
          hasWarnings,
          hasErrors,
          uploadWarningCount,
          uploadErrorCount,
          uploadTotalRecordCount,
          isSuccess,
          isReady: !hasUploaded && !needsRevalidation,
          isDisabled: false,
          needsRevalidation,
        };

        return acc;
      },
      { ...initialCardsState },
    ); // Start with the initialCardsState

    // Apply logic for when "Matters", "Matter Relationships", "Opening Balances", "Unbilled Time and Fee",
    // "Unbilled Expenses", "Memo", "Safe Custody Packet" and "Safe Custody Document"
    // cards should be marked is ready
    updatedCardsState.matter.isReady = updatedCardsState.contact.isSuccess;
    updatedCardsState.matterOpeningBalance.isReady =
      updatedCardsState.matter.isSuccess;
    updatedCardsState.unbilledTimeAndFee.isReady =
      updatedCardsState.matter.isSuccess;
    updatedCardsState.matterRelationship.isReady = updatedCardsState.matter.isSuccess;
    updatedCardsState.unbilledExpense.isReady =
      updatedCardsState.matter.isSuccess;
    updatedCardsState.invoice.isReady = updatedCardsState.matter.isSuccess;
    updatedCardsState.memo.isReady = updatedCardsState.matter.isSuccess;
    updatedCardsState.safeCustodyPacket.isReady =
      updatedCardsState.contact.isSuccess;
    updatedCardsState.safeCustodyDocument.isReady =
      updatedCardsState.safeCustodyPacket.isSuccess;

    // Apply logic for when "Matters" card should not be disabled if it needs revalidation
    if (updatedCardsState.matter.needsRevalidation) {
      updatedCardsState.matter.isDisabled = false;
    } else {
      updatedCardsState.matter.isDisabled =
        !updatedCardsState.contact.isSuccess &&
        !updatedCardsState.matter.hasUploaded;
    }

    // Apply logic for when "Matter Relationships" card should not be disabled if it needs revalidation
    if (updatedCardsState.matterRelationship.needsRevalidation) {
      updatedCardsState.matterRelationship.isDisabled = false;
    } else {
      updatedCardsState.matterRelationship.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.matterRelationship.hasUploaded;
    }

    // Apply logic for when "Opening Balances" card should not be disabled if it needs revalidation
    if (updatedCardsState.matterOpeningBalance.needsRevalidation) {
      updatedCardsState.matterOpeningBalance.isDisabled = false;
    } else {
      updatedCardsState.matterOpeningBalance.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.matterOpeningBalance.hasUploaded;
    }

    // Apply logic for when "Unbilled Time and Fees" card should not be disabled if it needs revalidation
    if (updatedCardsState.unbilledTimeAndFee.needsRevalidation) {
      updatedCardsState.unbilledTimeAndFee.isDisabled = false;
    } else {
      updatedCardsState.unbilledTimeAndFee.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.unbilledTimeAndFee.hasUploaded;
    }

    // Apply logic for when "Unbilled Expenses" card should not be disabled if it needs revalidation
    if (updatedCardsState.unbilledExpense.needsRevalidation) {
      updatedCardsState.unbilledExpense.isDisabled = false;
    } else {
      updatedCardsState.unbilledExpense.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.unbilledExpense.hasUploaded;
    }

    // Apply logic for when "Invoices" card should not be disabled if it needs revalidation
    if (updatedCardsState.invoice.needsRevalidation) {
      updatedCardsState.invoice.isDisabled = false;
    } else {
      updatedCardsState.invoice.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.invoice.hasUploaded;
    }

    // Apply logic for when "Memos" card should not be disabled if it needs revalidation
    if (updatedCardsState.memo.needsRevalidation) {
      updatedCardsState.memo.isDisabled = false;
    } else {
      updatedCardsState.memo.isDisabled =
        !updatedCardsState.matter.isSuccess &&
        !updatedCardsState.memo.hasUploaded;
    }

    // Apply logic for when "Safe Custody Packet" card should not be disabled if it needs revalidation
    if (updatedCardsState.safeCustodyPacket.needsRevalidation) {
      updatedCardsState.safeCustodyPacket.isDisabled = false;
    } else {
      updatedCardsState.safeCustodyPacket.isDisabled =
        !updatedCardsState.contact.isSuccess &&
        !updatedCardsState.safeCustodyPacket.hasUploaded;
    }

    // Apply logic for when "Safe Custody Document" card should not be disabled if it needs revalidation
    if (updatedCardsState.safeCustodyDocument.needsRevalidation) {
      updatedCardsState.safeCustodyDocument.isDisabled = false;
    } else {
      updatedCardsState.safeCustodyDocument.isDisabled =
        !updatedCardsState.safeCustodyPacket.isSuccess &&
        !updatedCardsState.safeCustodyDocument.hasUploaded;
    }

    // Update 'matterType.isDisabled' based on 'matter.needsRevalidation' or 'matter.isSuccess' status
    if (
      updatedCardsState.matter.needsRevalidation ||
      updatedCardsState.matter.isSuccess
    ) {
      updatedCardsState.matterType.isDisabled = false;
    } else {
      updatedCardsState.matterType.isDisabled =
        !updatedCardsState.matter.isSuccess;
    }

    setCardsState(updatedCardsState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, existingStaffCount]);

  return cardsState;
};
