export const formatCurrency = (value: number | undefined, region: string): string => {
    let formattedValue;
  
    switch (region) {
      case 'AU':
        formattedValue = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value || 0);
        // Remove the "A" from "A$"
        formattedValue = formattedValue.replace('A$', '$');
        break;
      case 'GB':
        formattedValue = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value || 0);
        break;
      case 'US':
        formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value || 0);
        break;
      default:
        formattedValue = value?.toFixed(2);
        break;
    }  
    return formattedValue || "";
  };
  