import { createContext, ReactNode, useContext } from "react";

import { SmokeballAppClient, useSDKClient, hasSDKClient } from "./hooks/smokeballSDK";
import { useLocalClient } from "./hooks/smokeballSDK.local";
import { UseSmokeballAppOptions } from "./types";

const unavailableContext = {
  auth: undefined,
  hostType: undefined,
  context: undefined,
  firm: undefined,
  staff: undefined,
  host: undefined,
};

export const useSetupSmokeballApp = (
  options?: UseSmokeballAppOptions,
): SmokeballAppClient => {
  if (hasSDKClient()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSDKClient(options);
  }

  if (process.env.REACT_APP_IS_LOCAL_CLIENT === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocalClient(options);
  }

  return unavailableContext;
};

const SmokeballAppContext =
  createContext<SmokeballAppClient>(unavailableContext);

const SmokeballContextProvider = SmokeballAppContext.Provider;

export const useSmokeballApp = () => useContext(SmokeballAppContext);

export type SmokeballProviderProps = UseSmokeballAppOptions & {
  children: ReactNode;
};

export const SmokeballProvider = ({
  children,
  observe,
}: SmokeballProviderProps) => {
  const value = useSetupSmokeballApp({ observe });

  return (<SmokeballContextProvider value={value}>
      {children}
    </SmokeballContextProvider>);
  
};

export {
  getLocalSmokeballAppContext,
  setLocalSmokeballAppContext,
  publishChange,
} from "./smokeballApp.utils.local";

export * from "./types";