import React from 'react';
import { COLOURS } from '../shared';

const FolderIcon = () => (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={COLOURS.cardIconColor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h16Z" />
    <path d="M8 17v-7h1.23l2.462 6h.616l2.461-6H16v7" />
  </svg>
);

export default FolderIcon;
