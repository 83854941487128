import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { transparentize } from "polished";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div<{ size: number; color?: string; loading?: boolean }>`
  display: ${(props) => (props.loading ? "block" : "none")};
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  min-width: ${(props) => props.size}rem;
  min-height: ${(props) => props.size}rem;

  border: ${(props) => Math.ceil(props.size / 2)}px solid ${(props) => transparentize(0.6, props.theme.palette.disabled.base)};
  border-top-color: ${(props) => props.color ? props.color : props.theme.palette.primary.base}; // Use the passed color or default to theme
  border-radius: 50%; // Creates a circular shape

  animation: ${spin} 1.1s infinite linear;
`;