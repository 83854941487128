import { css } from "@emotion/react";

import interVariable from "./Inter-VariableFont_slnt,wght.ttf";

const family = `Inter Variable`;

export const interStack = `"${family}", system-ui, sans-serif`;

export const inter = css`
  @font-face {
    font-family: "${family}";
    src: url("${interVariable}") format("truetype");
    font-weight: 1 999;
    font-display: swap;
  }
`;
