import { useMutation } from "react-query";
import { MigrationSetImport } from "../shared";
import log from "../shared/utils/log";
import { requestHelper } from "../shared/utils";

export type CompletionOfMigrationOptions = {
  accountId: string;
  migrationSetId: number;
  migrationImportId: number;
  totalRowCount: number;
  embedId: number;
  token?: () => Promise<string>;
};

const processCompletionOfMigrationDataTypeUpload = async ({
  accountId,
  migrationSetId,
  migrationImportId,
  totalRowCount,
  embedId,
  token,
}: CompletionOfMigrationOptions): Promise<MigrationSetImport> => {
  const endpoint = `/${migrationSetId}/${migrationImportId}/complete?totalRowCount=${totalRowCount}&embedId=${embedId}`;
  const data = await requestHelper(
    accountId,
    endpoint,
    "Failed to send data response to server.",
    "POST",    
    token,
  );
  log("Data for completed and uploaded card type: ", data);

  return data;
};

export const useCompletedDataType = () => {
  const { mutateAsync, isLoading } = useMutation(
    processCompletionOfMigrationDataTypeUpload
  );

  const handleCompletedData = async (
    options: CompletionOfMigrationOptions,
  ): Promise<void> => {
    try {
      await mutateAsync(options);
    } catch (error) {
      console.error("Error getting completed data from upload: ", error);
      throw error;
    }
  };

  return {
    handleCompletedData,
    isLoading,
  };
};
