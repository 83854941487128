import React from "react";
import styled from "@emotion/styled";
import { Page } from "../../../components";
import { CircleAlert } from "lucide-react";
import { COLOURS } from "../../../shared";

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100%;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background-color: ${COLOURS.errorBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const StyledHeading = styled.h2`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  margin: 0;
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  text-align: center;
  margin-top: 1.5rem;
`;

const ErrorScreen: React.FC = () => {
  return (
    <Page
      titleProps={{
        topLeftContent: <></>,
        bottomRightContent: <></>,
      }}
    >
      <CenteredContent>
        <IconWrapper>
          <CircleAlert
            color={COLOURS.cancelAndClearIconColor}
            strokeWidth={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </IconWrapper>
        <StyledHeading>Cannot connect to the account.</StyledHeading>
        <StyledHeading>Please refresh and try again.</StyledHeading>
        <StyledParagraph>
          If the issue persists, please contact our Support team for assistance.
        </StyledParagraph>
      </CenteredContent>
    </Page>
  );
};

export default ErrorScreen;
