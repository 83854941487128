import { useQuery } from "react-query";
import { MigrationUploadLink } from "../shared";
import log from "../shared/utils/log";

type LogFileProps = {
  accountId: string;
  migrationSetId: number;
  fileName: string;
  token?: () => Promise<string>;
 }

const fetchPresignedURLForLogFile = async ({
  accountId,
  migrationSetId,
  fileName,
  token,
 }: LogFileProps): Promise<MigrationUploadLink> => {
  try {
     const tokenValue = token && await token();
     log("Token value:", tokenValue);
 
     if (!tokenValue) {
       throw new Error("Token is not available");
     }
 
     const url = `/import/upload/${accountId}/${migrationSetId}/log?method=download&fileName=${encodeURIComponent(fileName)}`;
 
     const response = await fetch(url, {
       headers: { authorization: `Bearer ${tokenValue}` },
       method: "GET",
     });
 
     if (!response.ok) {
       throw new Error("Failed to retrieve the pre-signed URL for the log file");
     }
 
     const data = await response.json();
     log("Pre-signed URL for log file: ", { data });
 
     return data;
  } catch (error) {
     console.error("Error in fetchPresignedURLForLogFile:", error);
     throw error;
  }
 };
 

export const usePresignedURLForLogFile = ({ accountId, migrationSetId, fileName, token }: LogFileProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["logFile", accountId, migrationSetId, fileName],
    staleTime: 1000 * 60 * 60 * 24 * 5, // token is valid for > 5 days
    queryFn: () => fetchPresignedURLForLogFile({ accountId, migrationSetId, fileName, token }),
    // Do not run the query until all paramters are valid
    enabled: !!accountId && !!migrationSetId && !!fileName
  });
  
  return {
    data,
    isLoading,
  };
};