import React from "react";
import { Users } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";

interface CardStaffProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
}

export const CardStaff: React.FC<CardStaffProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
}) => {
  return (
    <Card
      image={
        <Users
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="staff"
      templateKey="staffImport"
      fileName="staff.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "staff")}
      headerText={ImportTypes.Staff}
      description="If you have staff members to import that have not already been entered into Smokeball (existing staff are automatically detected), upload them now. Otherwise, proceed to Contacts."
      cardState={cardsState["staff"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
    />
  );
};