import { useEffect, useState } from "react";

import { logError, SDKError } from "../utils/errors";

import { UseSDKApiOptions, Version } from "../types";

export const useHost = ({ client, isInit }: UseSDKApiOptions) => {
  const [version, setVersion] = useState<Version>();

  useEffect(() => {
    if (isInit && client) {
      client.host
        .version()
        .then(setVersion)
        .catch((err) => logError(new SDKError(err, { action: "version.get" })));
    }
  }, [isInit, client]);

  if (!isInit || !client) return undefined;

  return {
    close: () => {
      try {
        client.host.close();
      } catch (error) {
        throw new SDKError(error, { action: "host.close" });
      }
    },
    selectTab: (tab: number) => {
      try {
        client.host.selectTab(tab);
      } catch (error) {
        throw new SDKError(error, { action: "host.selectTab", input: { tab } });
      }
    },
    version,
    isEarlierThanVersion: ({
      major,
      minor,
    }: {
      major: number;
      minor: number;
    }): boolean | undefined => {
      if (!version) return undefined;
      
      if (version.major < major) {
        return true;
      }

      if (version.minor < minor) {
        return true;
      }
      
      return false;
    },
    open: (options: { url: string; title: string }) => {
      client.host.open(options);
    },
  };
};
