import React from "react";
import { File } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";

interface CardSafeCustodyDocumentProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardSafeCustodyDocument: React.FC<CardSafeCustodyDocumentProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
}) => {
  return (
    <Card
      image={
        <File
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="safeCustodyDocument"
      templateKey="safeCustodyDocumentImport"
      fileName="safeCustodyDocument.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "safeCustodyDocument")}
      headerText={ImportTypes.SafeCustodyDocuments}
      description="Documents must be associated to a Packet. Therefore, Packets must be imported alongside Documents. Once Packets are uploaded and valid, Documents can be added."
      cardState={cardsState["safeCustodyDocument"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Safe Custody Packets must be uploaded and valid before Safe Custody Documents can be added."
    />
  );
};
