import React from "react";
import { Contact } from "lucide-react";
import { QueryObserverResult } from "react-query";
import { COLOURS, ImportTypes, MigrationSetImport, MigrationSetWithImports } from "../../shared";
import { Card } from "../card";
import { getTemplateKey } from "../../functions/workflowFunctions";
interface CardContactProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<QueryObserverResult<MigrationSetWithImports, unknown>>;
  region: string;
}

export const CardContact: React.FC<CardContactProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      image={
        <Contact
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="contact"
      templateKey={getTemplateKey("contact", region)}
      fileName="contact.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "contact")}
      headerText={ImportTypes.Contacts}
      description="Contacts must be uploaded and valid before Matter data can be added."
      cardState={cardsState["contact"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
    />
  );
};
